import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbDatepickerModule, NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AutoquoteRoutingModule } from './autoquote-routing.module';
import { AutoquoteComponent } from './autoquote.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxMaskModule } from 'ngx-mask';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FileUploadModule } from 'ng2-file-upload';
import { QuoteService } from './service/quote.service';
import { BasicinfoComponent } from './basicinfo/basicinfo.component';
import { QuoteinfoComponent } from './quoteinfo/quoteinfo.component';
import { FileinfoComponent } from './fileinfo/fileinfo.component';
import { AdditionalservicesComponent } from './additionalservices/additionalservices.component';
import { AdditionServiceSidebarComponent } from './../addition-service-sidebar/addition-service-sidebar.component';
import { SuboptionComponent } from './../addition-service-sidebar/suboption/suboption.component';
import { QuoteHistoryComponent } from './quote-history/quote-history.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HeaderComponent } from './header/header.component';
import { NgxBootstrapSwitchModule } from 'ngx-bootstrap-switch';
import { AwaitingComponent } from './additionalservices/components/awaiting/awaiting.component';
import { OngoingComponent } from './additionalservices/components/ongoing/ongoing.component';
import { CompletedComponent } from './additionalservices/components/completed/completed.component';
import { PendingComponent } from './additionalservices/components/pending/pending.component';
import { DeclinedComponent } from './additionalservices/components/declined/declined.component';
import { IncompleteComponent } from './additionalservices/components/incomplete/incomplete.component';
import { CancelledComponent } from './additionalservices/components/cancelled/cancelled.component';
import { MomentDateFormatter, NgbStringAdapter } from './service/datepicker';
import { FormsiteComponent } from './formsite/formsite.component';
import { SafePipe } from './../shared/safepipe';
import { ModalModule } from 'ngx-bootstrap';
import { MetaService } from '../shared/meta.service';
import { QuickModule } from '@app/quick/quick.module';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DeviceDetectorModule.forRoot(),
    ReactiveFormsModule,
    NgxPaginationModule,
    NgbModule,
    TranslateModule,
    NgSelectModule,
    AutoquoteRoutingModule,
    BsDatepickerModule.forRoot(),
    PerfectScrollbarModule,
    Ng2TelInputModule,
    NgxMaskModule,
    AngularMultiSelectModule,
    FileUploadModule,
    NgxBootstrapSwitchModule.forRoot(),
    NgbDatepickerModule,
    ModalModule.forRoot(),
    QuickModule
  ],
  declarations: [
    AutoquoteComponent,
    BasicinfoComponent,
    QuoteinfoComponent,
    FileinfoComponent,
    AdditionalservicesComponent,
    AdditionServiceSidebarComponent,
    SuboptionComponent,
    QuoteHistoryComponent,
    HeaderComponent,
    AwaitingComponent,
    OngoingComponent,
    CompletedComponent,
    PendingComponent,
    DeclinedComponent,
    IncompleteComponent,
    CancelledComponent,
    FormsiteComponent,
    SafePipe
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
    { provide: NgbDateAdapter, useClass: NgbStringAdapter },
    DatePipe,
    QuoteService,
    MetaService
  ]
})
export class AutoquoteModule {}
