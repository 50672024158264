import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import { environment } from '@env/environment';
import { QuoteService } from './../autoquote/service/quote.service';
import { ConfirmationDialogService } from './../shared/confirmation-dialog/confirmation-dialog.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var $: any;
var self: any;
@Component({
  selector: 'addition-service-sidebar',
  templateUrl: './addition-service-sidebar.component.html',
  styleUrls: ['./addition-service-sidebar.component.scss']
})
export class AdditionServiceSidebarComponent implements OnInit, AfterViewInit {
  @Input() display: boolean = false;
  @Input() assets_root: string = '';
  @Input() autoquote: any;
  @ViewChild('additionalServiceInfo') additionalServiceInfo: ElementRef;
  @ViewChild('shipping') shipping: ElementRef;
  @ViewChild('finishButton') finishButton: ElementRef;
  isFormValid = false;
  totalSidebarAmount: number = 0;
  totalMailingSidebarAmount: number = 0;
  filter_status: any = { options: ['All', 'Ongoing', 'Completed'] };
  eml: any = '';
  version: string = environment.version;
  isSuboptionFormValValid: boolean = true;
  user: any = { email: '' };
  expend_text: any = 'Maximize';
  show_loader = false;
  currentTab: string = '';
  mailing_options: any = {
    options: [
      { value: '', show_name: '', name: 'Please select your option', amount: 0 },
      { value: 'e_preference', show_name: 'Electronic version only', name: 'Electronic version only', amount: 0 },
      {
        value: 'Certified_Mailing',
        show_name: 'Regular mailing (2-3 business days)',
        name: 'Regular mailing (2-3 business days): $30 USD',
        amount: 30
      },
      {
        value: 'Certified_Mailing_1Day',
        show_name: 'Overnight mailing (1 business day)',
        name: 'Overnight mailing (1 business day): $40 USD',
        amount: 40
      },
      {
        value: 'Certified_Mailing_International',
        show_name: 'International mailing (3-5 business days)',
        name: 'International mailing (3-5 business days): $60 USD',
        amount: 60
      },
      {
        value: 'Certified_Mailing_Canada',
        show_name: 'Canada mailing (1-3 business days)',
        name: 'Canada mailing (1-3 business days): $45 USD',
        amount: 45
      },
      {
        value: 'Regular_PickUp',
        show_name: 'Expedited Office pickup',
        name: 'Expedited Office pickup: $14.99 USD',
        amount: 14.99
      }
    ]
  };
  state_options: any = {
    options: [
      { value: '', name: 'Please state' },
      { value: 'AL', name: 'Alabama' },
      { value: 'AK', name: 'Alaska' },
      { value: 'AZ', name: 'Arizona' },
      { value: 'AR', name: 'Arkansas' },
      { value: 'CA', name: 'California' },
      { value: 'CO', name: 'Colorado' },
      { value: 'CT', name: 'Connecticut' },
      { value: 'DE', name: 'Delaware' },
      { value: 'DC', name: 'District Of Columbia' },
      { value: 'FL', name: 'Florida' },
      { value: 'GA', name: 'Georgia' },
      { value: 'HI', name: 'Hawaii' },
      { value: 'ID', name: 'Idaho' },
      { value: 'IN', name: 'Indiana' },
      { value: 'IA', name: 'Iowa' },
      { value: 'KS', name: 'Kansas' },
      { value: 'KY', name: 'Kentucky' },
      { value: 'LA', name: 'Louisiana' },
      { value: 'ME', name: 'Maine' },
      { value: 'MD', name: 'Maryland' },
      { value: 'MA', name: 'Massachusetts' },
      { value: 'MI', name: 'Michigan' },
      { value: 'MN', name: 'Minnesota' },
      { value: 'MS', name: 'Mississippi' },
      { value: 'MO', name: 'Missouri' },
      { value: 'MT', name: 'Montana' },
      { value: 'NE', name: 'Nebraska' },
      { value: 'NV', name: 'Nevada' },
      { value: 'NH', name: 'New Hampshire' },
      { value: 'NJ', name: 'New Jersey' },
      { value: 'NM', name: 'New Mexico' },
      { value: 'NY', name: 'New York' },
      { value: 'NC', name: 'North Carolina' },
      { value: 'ND', name: 'North Dakota' },
      { value: 'OH', name: 'Ohio' },
      { value: 'OK', name: 'Oklahoma' },
      { value: 'OR', name: 'Oregon' },
      { value: 'PA', name: 'Pennsylvania' },
      { value: 'RI', name: 'Rhode Island' },
      { value: 'SC', name: 'South Carolina' },
      { value: 'SD', name: 'South Dakota' },
      { value: 'TN', name: 'Tennessee' },
      { value: 'TX', name: 'Texas' },
      { value: 'UT', name: 'Utah' },
      { value: 'VT', name: 'Vermont' },
      { value: 'VA', name: 'Virginia' },
      { value: 'WA', name: 'Washington' },
      { value: 'WV', name: 'West Virginia' },
      { value: 'WI', name: 'Wisconsin' },
      { value: 'WY', name: 'Wyoming' }
    ]
  };
  additionalModel: any = [];
  additionalServiceOptions: any = [];
  additionalServiceFinalObj: any = [];
  additionalMailingServiceFinalObj: any = [];
  modalRef: BsModalRef;
  constructor(
    private quoteService: QuoteService,
    private confirmationDialogService: ConfirmationDialogService,
    private crf: ChangeDetectorRef,
    private modalService: BsModalService
  ) {}
  isSuboptionFormValChange($event: boolean) {
    this.isSuboptionFormValValid = $event;
  }

  additionalServiceDataLoaded() {
    if (
      (this.autoquote.isCertifiedTranslation === 'yes' && this.autoquote.status === 'pending') ||
      this.autoquote.status === 'incomplete'
    ) {
      this.additionalServiceOptions[1].mainOption.forEach((option: any) => {
        if (
          option.value != 'cert_free' &&
          option.value != 'none' &&
          option.options.indexOf(this.autoquote.typeOfCertifiedTranslation) !== -1
        ) {
          this.additionalModel[this.additionalServiceOptions[1].key] = option.value;
          if (this.autoquote.typeOfCertifiedTranslation != 'Other') {
            this.additionalModel[
              this.additionalServiceOptions[1].subOption[option.value][0].key
            ] = this.autoquote.typeOfCertifiedTranslation;
          } else {
            this.additionalModel['other_certification_c'] = this.autoquote.otherCertificationInput;
          }
        }
      });
      this.additionalOptionValueUpdated(this.shipping, this.additionalServiceInfo, true);
    }
  }

  additionalOptionValueUpdated(shipping: any, additionalServiceInfo: any, loadData = false) {
    setTimeout(() => {
      if (shipping && shipping.valid && additionalServiceInfo && additionalServiceInfo.isAllFormValid()) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
      this.shipping = shipping;
      this.additionalServiceInfo = additionalServiceInfo;
      this.additionalModel = Object.assign({}, this.additionalModel);
      this.additionalServiceFinalObj = [];
      this.additionalMailingServiceFinalObj = [];
      this.totalSidebarAmount = 0;
      this.totalMailingSidebarAmount = 0;
      this.additionalServiceOptions.forEach((additionOption: any) => {
        let obj = {};
        obj['image'] = additionOption.image;
        additionOption.amount = 0;
        additionOption.labelCount = 0;
        additionOption.mainOption.forEach((headerOption: any) => {
          if (headerOption.selected && headerOption.selected === true && !this.additionalModel[headerOption.key]) {
            this.additionalModel[headerOption.key] = headerOption.value;
          }

          if (this.additionalModel[headerOption.key] == headerOption.value && headerOption.value != 'none') {
            if (parseFloat(headerOption.amount) > 0 && headerOption.addAmount == true) {
              obj['maintext'] = headerOption.text;
              obj['mainvalue'] = headerOption.value;
              obj['mainkey'] = headerOption.key;
              obj['descOption'] = `${headerOption.text}\n`;
              obj['mailOption'] = `<h3>${headerOption.text}</h3>`;
              obj['isCopy'] = false;
              obj['required'] = headerOption.required ? headerOption.required : false;
              obj['mainamount'] = isNaN(headerOption.amount) ? 0 : parseFloat(headerOption.amount);
              obj['childs'] = [];
              additionOption.amount += isNaN(headerOption.amount) ? 0 : parseFloat(headerOption.amount);
            } else {
              obj['maintext'] = headerOption.text;
              obj['mainvalue'] = headerOption.value;
              obj['mainkey'] = headerOption.key;
              obj['mailOption'] = `<h3>${headerOption.text}</h3>`;
              obj['descOption'] = `${headerOption.text}\n`;
              obj['isCopy'] = false;
              obj['required'] = headerOption.required ? headerOption.required : false;
              obj['copyCount'] = 0;
              obj['mainamount'] = 0;
              obj['childs'] = [];
            }
          }
        });
        var parentCopyCount = 0;
        if (
          this.additionalModel[additionOption.key] != '' &&
          (additionOption.additionalInput || additionOption.isAdditionalService || additionOption.additionalDropdown)
        ) {
          if (additionOption.additionalInput) {
            obj['mailOption'] += `<p>${additionOption.additionalServiceInputOption.text}</p>`;
            obj['descOption'] += `${additionOption.additionalServiceInputOption.text}\n`;
            if (typeof this.additionalModel[additionOption.additionalServiceInputOption.key] == 'undefined') {
              this.additionalModel[additionOption.additionalServiceInputOption.key] = '';
            }
            obj['childs'].push({
              text: additionOption.additionalServiceInputOption.text,
              value: this.additionalModel[additionOption.additionalServiceInputOption.key],
              isCopy: false,
              copyCount: this.additionalModel[additionOption.additionalServiceOption.copyKey],
              key: additionOption.additionalServiceInputOption.key,
              required: additionOption.required ? additionOption.required : false,
              type: 'input',
              amount: 0
            });
          }
          if (additionOption.additionalDropdown) {
            if (typeof this.additionalModel[additionOption.additionalServiceDropdownOption.key] == 'undefined') {
              this.additionalModel[additionOption.additionalServiceDropdownOption.key] = '';
            }
            obj['mailOption'] += `<p>${additionOption.additionalServiceDropdownOption.text}</p>`;
            obj['descOption'] += `${additionOption.additionalServiceDropdownOption.text}\n`;
            obj['childs'].push({
              text: additionOption.additionalServiceDropdownOption.text,
              value: this.additionalModel[additionOption.additionalServiceDropdownOption.key],
              isCopy: false,
              copyCount: 0,
              key: additionOption.additionalServiceDropdownOption.key,
              required: additionOption.required ? additionOption.required : false,
              type: 'select',
              amount: 0
            });
          }
          if (
            additionOption.isAdditionalService &&
            additionOption.additionalServiceOption.isAdditionalService == true
          ) {
            if (!isNaN(additionOption.additionalServiceOption.amount)) {
              if (
                additionOption.isAdditionalService &&
                additionOption.additionalServiceOption.copy &&
                this.additionalModel[additionOption.additionalServiceOption.key] == true
              ) {
                if (isNaN(this.additionalModel[additionOption.additionalServiceOption.copyKey])) {
                  this.additionalModel[additionOption.additionalServiceOption.copyKey] = 1;
                }
                if (isNaN(additionOption.additionalServiceOption.amount)) {
                  additionOption.additionalServiceOption.amount = 0;
                }
                let singleAmount =
                  parseFloat(additionOption.additionalServiceOption.amount) *
                  this.additionalModel[additionOption.additionalServiceOption.copyKey];
                additionOption.amount += singleAmount;
                if (typeof obj['childs'] != 'undefined') {
                  obj['mailOption'] += `<p>${this.additionalModel[additionOption.additionalServiceOption.copyKey] +
                    ' x ' +
                    additionOption.additionalServiceOption.text}</p>`;
                  obj['descOption'] += `${this.additionalModel[additionOption.additionalServiceOption.copyKey] +
                    ' x ' +
                    additionOption.additionalServiceOption.text}\n`;
                  obj['childs'].push({
                    text:
                      this.additionalModel[additionOption.additionalServiceOption.copyKey] +
                      ' x ' +
                      additionOption.additionalServiceOption.text,
                    additionKey: additionOption.additionalServiceOption.key,
                    required: additionOption.required ? additionOption.required : false,
                    value: this.additionalModel[additionOption.additionalServiceOption.copyKey],
                    isCopy: true,
                    copyCount: this.additionalModel[additionOption.additionalServiceOption.copyKey],
                    key: additionOption.additionalServiceOption.copyKey,
                    amount: singleAmount
                  });
                }
              }
            }
          } else if (
            additionOption.isAdditionalService &&
            additionOption.additionalServiceOption.isAdditionalService == false &&
            additionOption.additionalServiceOption.copy
          ) {
            if (isNaN(this.additionalModel[additionOption.additionalServiceOption.copyKey])) {
              this.additionalModel[additionOption.additionalServiceOption.copyKey] = 1;
            }
            parentCopyCount = this.additionalModel[additionOption.additionalServiceOption.copyKey];
          }
        }
        var response = this.suboptionAdditionalServiceObj(additionOption, obj, parentCopyCount);
        obj = response.obj;
        additionOption.amount += response.totalAmount;
        if (
          (shipping && shipping.valid && additionalServiceInfo && additionalServiceInfo.isAllFormValid()) ||
          loadData ||
          additionOption.tab !== this.currentTab
        ) {
          if (obj.hasOwnProperty('mainamount')) {
            let maincount = obj['mainamount'] > 0 ? 1 : 0;
            additionOption.labelCount = obj['childs'].filter((data: any) => data.display == true).length + maincount;
            obj['labelCount'] = obj['childs'].filter((data: any) => data.display == true).length + maincount;
            if (additionOption.key == 'sprj1_addon_mailing_c') {
              this.additionalMailingServiceFinalObj.push(obj);
            } else {
              this.additionalServiceFinalObj.push(obj);
            }
          }
          if (additionOption.key != 'sprj1_addon_mailing_c') {
            this.totalSidebarAmount += parseFloat(additionOption.amount);
          } else {
            this.totalMailingSidebarAmount += parseFloat(additionOption.amount);
          }
        }
      });
    });
  }

  suboptionAdditionalServiceObj(additionOption: any, obj: any, parentCopyCount: number) {
    var totalAmount = 0;
    var response = { obj: obj, totalAmount: totalAmount };
    if (
      typeof this.additionalModel[additionOption.key] != 'undefined' &&
      this.additionalModel[additionOption.key] &&
      typeof additionOption.subOption != 'undefined' &&
      additionOption.subOption[this.additionalModel[additionOption.key]]
    ) {
      additionOption.subOption[this.additionalModel[additionOption.key]].forEach((suboption: any) => {
        if (suboption.containShippingForm) {
          if (
            this.additionalModel[suboption.key + 'street_address'] &&
            this.additionalModel[suboption.key + 'city'] &&
            this.additionalModel[suboption.key + 'state'] &&
            this.additionalModel[suboption.key + 'country'] &&
            this.additionalModel[suboption.key + 'zip_code'] &&
            this.additionalModel[suboption.key + 'phone_number']
          ) {
            if (obj['childs'].length == 0) {
              obj['maintext'] = obj['maintext'];
            } else {
              obj['childs'][obj['childs'].length - 1].text = obj['childs'][obj['childs'].length - 1].text;
            }
            obj['mailOption'] += `<p>${this.additionalModel[suboption.key + 'street_address'] +
              ', ' +
              this.additionalModel[suboption.key + 'city'] +
              ', ' +
              this.additionalModel[suboption.key + 'state'] +
              ', ' +
              this.additionalModel[suboption.key + 'country'] +
              ', ' +
              this.additionalModel[suboption.key + 'zip_code'] +
              '(' +
              this.additionalModel[suboption.key + 'phone_number'] +
              ')'}</p>`;
            obj['descOption'] += `${this.additionalModel[suboption.key + 'street_address'] +
              ', ' +
              this.additionalModel[suboption.key + 'city'] +
              ', ' +
              this.additionalModel[suboption.key + 'state'] +
              ', ' +
              this.additionalModel[suboption.key + 'country'] +
              ', ' +
              this.additionalModel[suboption.key + 'zip_code'] +
              '(' +
              this.additionalModel[suboption.key + 'phone_number'] +
              ')'}\n`;
            obj.childs.push({
              text:
                this.additionalModel[suboption.key + 'street_address'] +
                ', ' +
                this.additionalModel[suboption.key + 'city'] +
                ', ' +
                this.additionalModel[suboption.key + 'state'] +
                ', ' +
                this.additionalModel[suboption.key + 'country'] +
                ', ' +
                this.additionalModel[suboption.key + 'zip_code'] +
                '(' +
                this.additionalModel[suboption.key + 'phone_number'] +
                ')',
              amount: 0,
              containShippingForm: suboption.containShippingForm,
              key: suboption.key,
              parentKey: additionOption.key,
              required: suboption.required ? suboption.required : false,
              street_address: this.additionalModel[suboption.key + 'street_address'],
              city: this.additionalModel[suboption.key + 'city'],
              state: this.additionalModel[suboption.key + 'state'],
              country: this.additionalModel[suboption.key + 'country'],
              zip_code: this.additionalModel[suboption.key + 'zip_code'],
              phone_number: this.additionalModel[suboption.key + 'phone_number'],
              display: false
            });
          }
        }
        if (suboption.mainOption) {
          suboption.mainOption.forEach((sub: any) => {
            let languageCount = this.langaugePricingArray.totalLanguageCount;
            let warningText = '';
            let mailWarningText = '';
            if (sub.value === 'ata') {
              languageCount = this.langaugePricingArray.ataSupportedLanguageCount;
              if (this.langaugePricingArray.ataNonSupportedLanuageText !== '') {
                warningText = "We don't support ATA for:  " + this.langaugePricingArray.ataNonSupportedLanuageText;
                mailWarningText =
                  "ATA pricing doesn't include for:  " + this.langaugePricingArray.ataNonSupportedLanuageText;
              }
            }
            if (sub.selected && sub.selected === true && !this.additionalModel[sub.key]) {
              this.additionalModel[sub.key] = sub.value;
              this.additionalOptionValueUpdated(this.shipping, this.additionalServiceInfo, true);
            }
            if (sub.type == 'select') {
              if (!this.additionalModel[suboption.key + 'statedropdown']) {
                this.additionalModel[suboption.key + 'statedropdown'] = '';
              }
              if (obj['childs'].length == 0) {
                obj['mailOption'] += `<p>${obj['maintext'] +
                  ' -- ' +
                  this.additionalModel[suboption.key + 'statedropdown']}</p>`;

                obj['descOption'] += `${obj['maintext'] +
                  ' -- ' +
                  this.additionalModel[suboption.key + 'statedropdown']}\n`;
                obj['maintext'] =
                  obj['maintext'] +
                  ' -- ' +
                  this.getShortLengthText(this.additionalModel[suboption.key + 'statedropdown']).toUpperCase();
              } else {
                obj['mailOption'] += `<p>${obj['childs'][obj['childs'].length - 1].text +
                  ' -- ' +
                  this.additionalModel[suboption.key + 'statedropdown']}</p>`;

                obj['descOption'] += `${obj['childs'][obj['childs'].length - 1].text +
                  ' -- ' +
                  this.additionalModel[suboption.key + 'statedropdown']}\n`;
                obj['childs'][obj['childs'].length - 1].text =
                  obj['childs'][obj['childs'].length - 1].text +
                  ' -- ' +
                  this.getShortLengthText(this.additionalModel[suboption.key + 'statedropdown']).toUpperCase();
              }
              obj['mailOption'] += `<p>${sub.text} x ${languageCount} langauge(s)</p>`;
              obj['descOption'] += `${sub.text} x ${languageCount} langauge(s)\n`;
              if (mailWarningText) {
                obj['mailOption'] += `<p style="color:red">${mailWarningText}</p>`;
                obj['descOption'] += `${mailWarningText}\n`;
              }
              obj['childs'].push({
                text: sub.text + ' x ' + languageCount + ' langauge(s)',
                warning: warningText,
                key: sub.key,
                parentKey: additionOption.key,
                required: sub.required ? sub.required : false,
                value: this.additionalModel[suboption.key + 'statedropdown']
                  ? this.additionalModel[suboption.key + 'statedropdown']
                  : '',
                isCopy: false,
                display: false,
                amount: 0
              });
            }
            if (this.additionalModel[sub.key] == true && sub.depended && this.additionalModel[sub.depended] != true) {
              this.additionalModel[sub.key] = false;
            }
            if (
              (this.additionalModel[sub.key] == sub.value && sub.type != 'checkbox') ||
              (this.additionalModel[sub.key] == true && sub.type == 'checkbox')
            ) {
              if (sub.amount != '' && !isNaN(sub.amount)) {
                if (
                  sub.isAdditionalService &&
                  sub.additionalServiceOption.isAdditionalService == false &&
                  sub.additionalServiceOption.copy
                ) {
                  if (isNaN(this.additionalModel[sub.additionalServiceOption.copyKey])) {
                    this.additionalModel[sub.additionalServiceOption.copyKey] = 1;
                  }
                  parentCopyCount = this.additionalModel[sub.additionalServiceOption.copyKey];
                }
                if (typeof obj['childs'] != 'undefined') {
                  if (parentCopyCount > 0) {
                    if (obj['mainkey'] == 'sprj1_addon_mailing_c') {
                      obj['mailOption'] += `<p>Additional Copy Details:</p>`;
                      obj['descOption'] += `Additional Copy Details:\n`;
                    }
                    let subamount = isNaN(sub.amount) ? 0 : parseFloat(sub.amount);
                    obj['mailOption'] += `<p>${sub.text +
                      ' x ' +
                      parentCopyCount +
                      ' x $' +
                      parentCopyCount * subamount}USD</p>`;
                    obj['descOption'] += `${sub.text +
                      ' x ' +
                      parentCopyCount +
                      ' x $' +
                      parentCopyCount * subamount}USD\n`;
                    if (mailWarningText) {
                      obj['mailOption'] += `<p style="color:red">${mailWarningText}</p>`;
                      obj['descOption'] += `${mailWarningText}\n`;
                    }
                    obj['childs'].push({
                      text: parentCopyCount + ' x ' + sub.text,
                      key: sub.key,
                      warning: warningText,
                      value: sub.value,
                      required: sub.required ? sub.required : false,
                      amount: languageCount * parentCopyCount * subamount,
                      isCopy: false,
                      display: true,
                      copyCount: parentCopyCount
                    });
                    totalAmount += languageCount * parentCopyCount * subamount;
                  } else {
                    obj['mailOption'] += `<p>${sub.text +
                      ' x ' +
                      languageCount +
                      ' langauge(s) ' +
                      ' x $' +
                      parseFloat(sub.amount)}USD</p>`;

                    obj['descOption'] += `${sub.text +
                      ' x ' +
                      languageCount +
                      ' langauge(s) ' +
                      ' x $' +
                      parseFloat(sub.amount)}USD\n`;
                    if (mailWarningText) {
                      obj['mailOption'] += `<p style="color:red">${mailWarningText}</p>`;
                      obj['descOption'] += `${mailWarningText}\n`;
                    }
                    obj['childs'].push({
                      text: sub.text + ' x ' + languageCount + ' langauge(s)',
                      key: sub.key,
                      warning: warningText,
                      value: sub.value,
                      required: sub.required ? sub.required : false,
                      amount: languageCount * parseFloat(sub.amount),
                      isCopy: false,
                      display: true,
                      copyCount: 0
                    });
                    let subamount = isNaN(sub.amount) ? 0 : languageCount * parseFloat(sub.amount);
                    totalAmount += subamount;
                  }
                } else {
                  let text = '';
                  let amount = 0;
                  if (parentCopyCount > 0) {
                    text = sub.text + ' x ' + parentCopyCount;
                    amount = isNaN(sub.amount) ? 0 : parentCopyCount * languageCount * parseFloat(sub.amount);
                    obj['isCopy'] = true;
                    obj['copyCount'] = parentCopyCount;
                    let subamount = isNaN(sub.amount) ? 0 : languageCount * parseFloat(sub.amount);
                    totalAmount += parentCopyCount * subamount;
                  } else {
                    text = sub.text;
                    amount = isNaN(sub.amount) ? 0 : languageCount * parseFloat(sub.amount);
                    obj['isCopy'] = false;
                    obj['copyCount'] = 0;
                    totalAmount += isNaN(sub.amount) ? 0 : languageCount * parseFloat(sub.amount);
                  }
                  obj['mailOption'] = `<h3>${additionOption.header}</h3><p>${text +
                    ' x ' +
                    languageCount +
                    ' langauge(s) x $' +
                    amount}USD</p>`;
                  obj['descOption'] = `${additionOption.header}</h3><p>${text +
                    ' x ' +
                    languageCount +
                    ' langauge(s) x $' +
                    amount}USD\n`;
                  obj['maintext'] = additionOption.header;
                  obj['mainvalue'] = '';
                  obj['mainkey'] = additionOption.key;
                  obj['isCopy'] = false;
                  obj['required'] = additionOption.required ? additionOption.required : false;
                  obj['copyCount'] = 0;
                  obj['mainamount'] = 0;
                  if (mailWarningText) {
                    obj['mailOption'] += `<p style="color:red">${mailWarningText}</p>`;
                    obj['descOption'] += `${mailWarningText}\n`;
                  }
                  obj['childs'] = [
                    {
                      text: text + ' x ' + languageCount + ' langauge(s)',
                      warning: warningText,
                      key: sub.key,
                      value: sub.value,
                      required: sub.required ? sub.required : false,
                      amount: amount,
                      isCopy: false,
                      display: true,
                      copyCount: 0
                    }
                  ];
                }
              }
              if (sub.additionalInput || sub.isAdditionalService || sub.additionalDropdown) {
                if (sub.additionalInput && sub.additionalServiceInputOption) {
                  if (typeof this.additionalModel[sub.additionalServiceInputOption.key] == 'undefined') {
                    this.additionalModel[sub.additionalServiceInputOption.key] = '';
                  }
                  if (obj['childs'].length == 0) {
                    obj['mailOption'] += `<p>${obj['maintext'] +
                      ' -- ' +
                      this.additionalModel[sub.additionalServiceInputOption.key]}</p>`;

                    obj['descOption'] += `${obj['maintext'] +
                      ' -- ' +
                      this.additionalModel[sub.additionalServiceInputOption.key]}\n`;
                    obj['maintext'] =
                      obj['maintext'] +
                      ' -- ' +
                      this.getShortLengthText(this.additionalModel[sub.additionalServiceInputOption.key]);
                  } else {
                    obj['mailOption'] += `<p>${obj['childs'][obj['childs'].length - 1].text +
                      ' -- ' +
                      this.additionalModel[sub.additionalServiceInputOption.key]}</p>`;
                    obj['descOption'] += `${obj['childs'][obj['childs'].length - 1].text +
                      ' -- ' +
                      this.additionalModel[sub.additionalServiceInputOption.key]}\n`;
                    obj['childs'][obj['childs'].length - 1].text =
                      obj['childs'][obj['childs'].length - 1].text +
                      ' -- ' +
                      this.getShortLengthText(this.additionalModel[sub.additionalServiceInputOption.key]);
                  }
                  obj['mailOption'] += `<p>${sub.additionalServiceInputOption.text} x ${languageCount} langauge(s)</p>`;

                  obj['descOption'] += `${sub.additionalServiceInputOption.text} x ${languageCount} langauge(s)\n`;
                  if (warningText) {
                    obj['mailOption'] += `<p style="color:red">${mailWarningText}</p>`;
                    obj['descOption'] += `${mailWarningText}\n`;
                  }
                  obj['childs'].push({
                    text: sub.additionalServiceInputOption.text + ' x ' + languageCount + ' langauge(s)',
                    warning: warningText,
                    value: this.additionalModel[sub.additionalServiceInputOption.key],
                    isCopy: false,
                    additionKey: sub.additionalServiceInputOption.key,
                    copyCount: 0,
                    required:
                      sub.additionalServiceOption && sub.additionalServiceOption.required
                        ? sub.additionalServiceOption.required
                        : false,
                    key: sub.additionalServiceInputOption.key,
                    amount: 0,
                    display: false
                  });
                }
                if (sub.additionalDropdown && sub.additionalServiceDropdownOption) {
                  if (typeof this.additionalModel[sub.additionalServiceDropdownOption.key] == 'undefined') {
                    this.additionalModel[sub.additionalServiceDropdownOption.key] = '';
                  }
                  if (obj['childs'].length == 0) {
                    obj['mailOption'] += `<p>${obj['maintext'] +
                      ' -- ' +
                      this.additionalModel[sub.additionalServiceDropdownOption.key]}</p>`;

                    obj['descOption'] += `${obj['maintext'] +
                      ' -- ' +
                      this.additionalModel[sub.additionalServiceDropdownOption.key]}\n`;
                    obj['maintext'] =
                      obj['maintext'] +
                      ' -- ' +
                      this.getShortLengthText(this.additionalModel[sub.additionalServiceDropdownOption.key]);
                  } else {
                    obj['mailOption'] += `<p>${obj['childs'][obj['childs'].length - 1].text +
                      ' -- ' +
                      this.additionalModel[sub.additionalServiceDropdownOption.key]}</p>`;

                    obj['descOption'] += `${obj['childs'][obj['childs'].length - 1].text +
                      ' -- ' +
                      this.additionalModel[sub.additionalServiceDropdownOption.key]}\n`;
                    obj['childs'][obj['childs'].length - 1].text =
                      obj['childs'][obj['childs'].length - 1].text +
                      ' -- ' +
                      this.getShortLengthText(this.additionalModel[sub.additionalServiceDropdownOption.key]);
                  }
                  obj['mailOption'] += `<p>${sub.additionalServiceDropdownOption.text}</p>`;
                  obj['descOption'] += `${sub.additionalServiceDropdownOption.text}\n`;
                  if (warningText) {
                    obj['mailOption'] += `<p style="color:red">${mailWarningText}</p>`;
                    obj['descOption'] += `${mailWarningText}\n`;
                  }
                  obj['childs'].push({
                    text: sub.additionalServiceDropdownOption.text,
                    warning: '',
                    value: this.additionalModel[sub.additionalServiceDropdownOption.key],
                    isCopy: false,
                    additionKey: sub.additionalServiceDropdownOption.key,
                    copyCount: 0,
                    required:
                      sub.additionalServiceDropdownOption && sub.additionalServiceDropdownOption.required
                        ? sub.additionalServiceDropdownOption.required
                        : false,
                    key: sub.additionalServiceDropdownOption.key,
                    amount: 0,
                    display: false
                  });
                }
                if (
                  sub.isAdditionalService &&
                  sub.additionalServiceOption.isAdditionalService &&
                  sub.additionalServiceOption.selectedLanguage.length > 0
                ) {
                  let languageCount = 0;
                  sub.additionalServiceOption.selectedLanguage.forEach((language: any) => {
                    if (sub.value !== 'ata' || (sub.value === 'ata' && language.is_language_support_ata === '1')) {
                      languageCount = languageCount + language.countValue;
                    }
                  });
                  if (
                    sub.isAdditionalService &&
                    sub.additionalServiceOption.copy &&
                    this.additionalModel[sub.additionalServiceOption.key] == true
                  ) {
                    if (isNaN(this.additionalModel[sub.additionalServiceOption.copyKey])) {
                      this.additionalModel[sub.additionalServiceOption.copyKey] = 1;
                    }
                    let singleAmount = languageCount * parseFloat(sub.additionalServiceOption.amount);
                    // * this.additionalModel[sub.additionalServiceOption.copyKey];
                    totalAmount += singleAmount;
                    if (typeof obj['childs'] != 'undefined') {
                      obj['mailOption'] += `<p>${this.additionalModel[sub.additionalServiceOption.copyKey] +
                        ' x ' +
                        sub.additionalServiceOption.text}</p>`;
                      obj['descOption'] += `${this.additionalModel[sub.additionalServiceOption.copyKey] +
                        ' x ' +
                        sub.additionalServiceOption.text}\n`;
                      obj['childs'].push({
                        text:
                          this.additionalModel[sub.additionalServiceOption.copyKey] +
                          ' x ' +
                          sub.additionalServiceOption.text,
                        warning: '',
                        value: this.additionalModel[sub.additionalServiceOption.copyKey],
                        isCopy: true,
                        required: sub.additionalServiceOption.required ? sub.additionalServiceOption.required : false,
                        additionKey: sub.additionalServiceOption.key,
                        copyCount: this.additionalModel[sub.additionalServiceOption.copyKey],
                        key: sub.additionalServiceOption.copyKey,
                        amount: singleAmount,
                        display: true
                      });
                    }
                    sub.additionalServiceOption.selectedLanguage.forEach((language: any) => {
                      if (sub.value !== 'ata' || (sub.value === 'ata' && language.is_language_support_ata === '1')) {
                        let copyText = language.countValue > 1 ? 'Copies' : 'Copy';
                        obj['mailOption'] += `<p>${language.itemName +
                          ' x ' +
                          language.countValue +
                          ' ' +
                          copyText}</p>`;

                        obj['descOption'] += `${language.itemName + ' x ' + language.countValue + ' ' + copyText}\n`;
                        obj['childs'].push({
                          text: language.itemName + ' x ' + language.countValue + ' ' + copyText,
                          class: 'level-2',
                          warning: '',
                          value: language.countValue,
                          isCopy: true,
                          required: true,
                          additionKey: language.id + '_' + sub.additionalServiceOption.key,
                          copyCount: language.countValue,
                          key: language.id + '_' + sub.additionalServiceOption.copyKey,
                          amount: null,
                          display: true
                        });
                      } else {
                        obj['mailOption'] += `<p>${language.itemName}</p>`;
                        obj['mailOption'] += `<p style="color:red">Currently, We are not supporting</p>`;
                        obj['childs'].push({
                          text: language.itemName,
                          class: 'level-2',
                          warning: 'Currently, We are not supporting',
                          value: language.countValue,
                          isCopy: true,
                          required: true,
                          additionKey: language.id + '_' + sub.additionalServiceOption.key,
                          copyCount: language.countValue,
                          key: language.id + '_' + sub.additionalServiceOption.copyKey,
                          amount: null,
                          display: true
                        });
                      }
                    });
                  }
                }
              }
            }
          });
        }
        response = this.suboptionAdditionalServiceObj(suboption, obj, 0);
      });
    }
    return { obj: response.obj, totalAmount: totalAmount + response.totalAmount };
  }

  apostilleNotrizationInfoPopup(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'apostille-notrization-modal' }));
  }

  getShortLengthText(name: string, maxSizeAllowed = 30) {
    if (name == null) {
      return '';
    }
    const dot = '...';
    const dotLength = dot.length;

    const nameLength = name.length;
    const pointIndex = name.lastIndexOf('.');

    const ext = name.slice(pointIndex, nameLength);

    let shortName = name;

    if (nameLength > maxSizeAllowed) {
      const end = maxSizeAllowed - dotLength;
      shortName = `${name.slice(0, end)}${dot}`;
      return shortName;
    }
    return shortName;
  }
  ngOnInit() {
    self = this;
    this.show_loader = true;
    this.quoteService.getAdditionServiceObj().subscribe(data => {
      this.show_loader = false;
      this.additionalServiceOptions = data;
      this.additionalServiceDataLoaded();
      this.additionalOptionValueUpdated(this.shipping, this.additionalServiceInfo, false);
    });
    this.getTotalCountForLanguage();
  }
  langaugePricingArray = {
    ataSupportedLanguageCount: 0,
    ataNonSupportedLanguageCount: 0,
    ataNonSupportedLanuageText: '',
    totalLanguageCount: 0
  };
  getTotalCountForLanguage() {
    if (this.autoquote.isAutoquote) {
      const languages = [...this.autoquote.target_language, ...this.autoquote.source_language];
      const totalTargetLanguages = languages.filter(lang => lang.category !== 'English' && lang.itemName !== 'English');
      this.langaugePricingArray.totalLanguageCount = totalTargetLanguages.length;
      totalTargetLanguages.forEach((language: any) => {
        if (language.is_language_support_ata === '1') {
          this.langaugePricingArray.ataSupportedLanguageCount++;
        } else {
          this.langaugePricingArray.ataNonSupportedLanguageCount++;
          if (this.langaugePricingArray.ataNonSupportedLanuageText === '') {
            this.langaugePricingArray.ataNonSupportedLanuageText = language.itemName;
          } else {
            this.langaugePricingArray.ataNonSupportedLanuageText =
              this.langaugePricingArray.ataNonSupportedLanuageText + ', ' + language.itemName;
          }
        }
      });
    }
  }
  removeInCopy(index: any) {
    if (isNaN(this.additionalModel[index]) || this.additionalModel[index] <= 0) {
      this.additionalModel[index] = 1;
    }
    if (this.additionalModel[index] > 1) {
      this.additionalModel[index] = parseInt(this.additionalModel[index]) - 1;
    }
    this.additionalOptionValueUpdated(this.shipping, this.additionalServiceInfo);
  }
  addInCopy(index: any) {
    if (isNaN(this.additionalModel[index]) || this.additionalModel[index] <= 0) {
      this.additionalModel[index] = 1;
    }
    this.additionalModel[index] = parseInt(this.additionalModel[index]) + 1;
    this.additionalOptionValueUpdated(this.shipping, this.additionalServiceInfo);
  }
  ngAfterViewInit() {
    var folder_path = environment.domain_folder;
    if ($('body').hasClass('functionInitialized') === false) {
      $('body').addClass('functionInitialized');
      $.getScript(folder_path + '/assets/js/jquery.js', function() {
        $(document).on('click', '.search_form', function() {
          $('#search_quote_form').submit();
        });

        $(document).on('click', '.add-services-click', function() {
          $('body').toggleClass('open-sidebar');
        });

        $(document).on('click', '.sidebar-additional-service', function() {
          let tab = $(this).data('tab');
          $('body').toggleClass('open-sidebar');
          var tab_id = 'tab-' + tab;
          self.currentTab = tab_id;
          $('.ad-serivces-all li  a').removeClass('current');
          $('.tab-content-side').removeClass('current');
          $(this).addClass('current');
          $('#' + tab_id).addClass('current');
          self.crf.markForCheck();
        });

        $(document).on('change', '.filter_field', function() {
          var a = $('.max-history-collpase').hasClass('content-open');
          if (!a) {
            $('.max-history-collpase').addClass('content-open');
            $('.open-close-text').text('Minimize');
          }
        });

        $(document).on('click', '.refernce-id-new', function() {
          $(this).toggleClass('add-collpase-arrow');
          $(this)
            .next('.both-items-status')
            .slideToggle('add-collpase');
        });
        $(document).on('click', '.main-service-click', function() {
          $(this)
            .parent()
            .next('.additional-services-mails')
            .slideToggle('add-collpase');
          $(this).toggleClass('add-collpase-arrow');
        });

        $(document).on('click', '.area-pull', function() {
          $(this)
            .parent()
            .next('.additional-services-mails12')
            .slideToggle('add-collpase1');
          $(this).toggleClass('add-collpase-arrow1');
        });

        $(document).on('click', '.additional_submit_button', function() {
          $('body').removeClass('open-sidebar');
          $('.tab-content-side').removeClass('current');
        });

        $(document).on('click', '.cross-icon', function() {
          $('body').removeClass('open-sidebar');
        });

        $(document).on('click', '.mobile-overlay', function() {
          if ($('.additional_submit_button').prop('disabled') == false) {
            $('body').removeClass('open-sidebar');
            $('.tab-content-side').removeClass('current');
          } else {
            $('input.ng-invalid')
              .focus()
              .addClass('required-validate');
            setTimeout(() => $('input.ng-invalid').removeClass('required-validate'), 5000);
            self.confirmationDialogService
              .confirm('Missing Values', 'Please choose a value for all required fields.', false)
              .then((confirmed: any) => {})
              .catch(() => {});
          }
        });

        $(document).on('click', '.ad-serivces-all li a', function() {
          var tab_id = $(this).attr('data-tab');

          $('.ad-serivces-all li  a').removeClass('current');
          $('.tab-content-side').removeClass('current');

          $(this).addClass('current');
          $('#' + tab_id).addClass('current');
        });

        $(document).on('click', '.go-back', function() {
          if (self.isFormValid || self.currentTab == '') {
            self.currentTab = '';
            $('.tab-content-side').removeClass('current');
          } else {
            $('input.ng-invalid')
              .focus()
              .addClass('required-validate');
            setTimeout(() => $('input.ng-invalid').removeClass('required-validate'), 5000);
            self.confirmationDialogService
              .confirm('Missing Values', 'Please choose a value for all required fields.', false)
              .then((confirmed: any) => {})
              .catch(() => {});
          }
        });

        $(document).on('mouseover', '.tooltip_btn_one', function() {
          if (!$(this).is(':disabled')) {
            $('.top.in').attr('style', 'display: none !important');
          } else {
            $('.top.in').attr('style', 'display: block !important');
          }
        });
        $(document).on('mouseover', '.tooltip_btn_second', function() {
          if (!$(this).is(':disabled')) {
            $('.top.in').attr('style', 'display: none !important');
          } else {
            $('.top.in').attr('style', 'display: block !important');
          }
        });
        $(document).on('mouseover', '.tooltip_btn_third', function() {
          if (!$(this).is(':disabled')) {
            $('.top.in').attr('style', 'display: none !important');
          } else {
            $('.top.in').attr('style', 'display: block !important');
          }
        });
      });
    }
  }
}
