import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { QuoteService } from './../../../service/quote.service';
declare var $: any;
@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss']
})
export class PendingComponent implements OnInit, AfterViewInit {
  @Input() autoquote: any = {};
  @Input() sidebar: any = {};
  @Input() discountApplied: boolean = false;
  @Input() assets_root: string = environment.domain_folder;
  @Input() isServiceListMinimized: boolean = true;
  @Input() isMailingServiceListMinimized: boolean = true;
  showFiles: boolean = false;
  @Input() is_desktop: boolean = true;
  @Output() downloadFiles = new EventEmitter<string>();
  @Output() deleteAdditionalService = new EventEmitter<number>();
  @Output() deleteAdditionalServiceChild = new EventEmitter<{}>();
  @Output() deleteMailingService = new EventEmitter<number>();
  @Output() deleteMailingServiceChild = new EventEmitter<{}>();
  @Output() isServiceListMinimizedChanged = new EventEmitter<boolean>();
  @Output() isMailingServiceListMinimizedChanged = new EventEmitter<boolean>();
  applicable_text = '';
  constructor(private router: Router, private quoteService: QuoteService) {}

  ngOnInit() {}
  ngAfterViewInit() {
    if (this.autoquote.submitted === true) {
      $('body').mouseleave(($event: any) => {
        if (this.autoquote.submitted === true) {
          $('#leavingWindow')
            .removeClass('in')
            .addClass('show');
          this.autoquote.submitted = false;
        }
      });
    }
  }
  downloadFile() {
    this.downloadFiles.emit();
  }
  removeAdditionalService(index: number) {
    this.deleteAdditionalService.emit(index);
  }
  removeAdditionalServiceChild(parent: any, child: any) {
    this.deleteAdditionalServiceChild.emit({ parent, child });
  }
  removeMailingService(index: number) {
    this.deleteMailingService.emit(index);
  }
  removeMailingServiceChild(parent: any, child: any) {
    this.deleteMailingServiceChild.emit({ parent, child });
  }
  checkNumber(value: any) {
    return !isNaN(value);
  }
  closeLeaveWindowPopup() {
    $('#leavingWindow')
      .removeClass('show')
      .addClass('in');
    this.autoquote.submitted = false;
  }
  saveUserInputOnLeaving(selection: string) {
    this.quoteService
      .updateClientLeavingReason({
        crm_id: this.autoquote.crm_id,
        crm_quote_type: this.autoquote.quote_type,
        selection: selection
      })
      .subscribe(
        (res: any) => {
          this.closeLeaveWindowPopup();
        },
        error => {
          this.closeLeaveWindowPopup();
        }
      );
  }
  discussionPage() {
    this.router.navigate(['discussion/'], {
      queryParams: { id: this.autoquote.crm_id, type: this.autoquote.quote_type }
    });
    return false;
  }
}
