import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { AutoquoteComponent } from './autoquote.component';
import { QuickComponent } from '@app/quick/quick.component';

const routes: Routes = [
  { path: '', component: AutoquoteComponent, data: { title: extract('Quotes') } },
  { path: 'quick', component: QuickComponent },
  {
    path: 'affiliate',
    component: QuickComponent,
    data: { title: extract('Affiliate'), segment: extract('affiliate') }
  },
  {
    path: 'affiliate-signup-iframe',
    component: QuickComponent,
    data: { title: extract('Affiliate Signup Iframe'), segment: extract('affiliate-signup-iframe') }
  },
  {
    path: 'certified-quote',
    component: AutoquoteComponent,
    data: { title: extract('Certified Quote'), segment: extract('certified-quote') }
  },
  {
    path: 'translation-quote',
    component: AutoquoteComponent,
    data: { title: extract('Quotes'), segment: extract('translation-quote') }
  },
  {
    path: 'interpreting-quote',
    component: AutoquoteComponent,
    data: { title: extract('Interpreting Quote'), segment: extract('interpreting-quote') }
  },
  {
    path: 'multilingual-marketing-quote',
    component: AutoquoteComponent,
    data: { title: extract('multilingual marketing quote'), segment: extract('multilingual-marketing-quote') }
  },
  {
    path: 'business-services-quote',
    component: AutoquoteComponent,
    data: { title: extract('business services quote'), segment: extract('business-services-quote') }
  },
  {
    path: 'localization-quote',
    component: AutoquoteComponent,
    data: { title: extract('localization quote'), segment: extract('localization-quote') }
  },
  {
    path: 'transcription-quote',
    component: AutoquoteComponent,
    data: { title: extract('transcription quote'), segment: extract('transcription-quote') }
  },
  {
    path: 'subtitling-quote',
    component: AutoquoteComponent,
    data: { title: extract('subtitling quote'), segment: extract('subtitling-quote') }
  },
  {
    path: 'academic-evaluation',
    component: AutoquoteComponent,
    data: { title: extract('academic evaluation'), segment: extract('academic-evaluation') }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AutoquoteRoutingModule {}
