import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
var previousLink: any;
@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(@Inject(DOCUMENT) private dom: any) {}

  createCanonicalURL() {
    if (previousLink) {
      previousLink.remove();
    }
    let link: HTMLLinkElement = this.dom.createElement('link');
    previousLink = link;
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
  }
}
