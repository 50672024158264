import { Action } from '@ngrx/store';
import { Resultbar } from './../models/resultbar.model';
import * as ResultbarActions from './../actions/resultbar.actions';
// initial state
const initialState: Resultbar = {
  referenceId: '',
  project_type: '',
  project_cost: '',
  additionalServices: '',
  total: ''
};

// Reducer function
export function resultReducer(state: Resultbar[] = [initialState], action: ResultbarActions.Actions) {
  // conditional actions
  switch (action.type) {
    case ResultbarActions.MANAGE_RESULT:
      return [action.payload];
    default:
      return state;
  }
}
