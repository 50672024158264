import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@env/environment';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() isLoading = false;
  @Input() message: string;
  assets_root = environment.domain_folder;

  constructor() {}

  ngOnInit() {}
}
