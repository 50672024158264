// Section 1
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Quote } from './../models/quote.model';

// Section 2
export const ADD_UPDATE_QUOTE = '[QUOTE] UPDATE';

// Section 3
export class AddUpdateQuote implements Action {
  readonly type = ADD_UPDATE_QUOTE;

  constructor(public payload: Quote) {}
}

// Section 4
export type Actions = AddUpdateQuote;
