import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeclineComponent } from './decline/decline.component';
import { RenegotiateComponent } from './renegotiate/renegotiate.component';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'decline', component: DeclineComponent },
    { path: 'renegotiate', component: RenegotiateComponent }
  ])
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class DeclineRoutingModule {}
