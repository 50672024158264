import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { QuoteService } from '../../home/quote.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit, AfterViewInit {
  sub: any;
  id: string = '';
  type: string = '';
  assets_root = environment.domain_folder;
  userDetail: any = {};
  errors: string = '';
  success_message = false;
  dicussion: any = {
    query_description: '',
    selector: 'discuss',
    crm_type: '',
    billing_type: 'credit_card',
    crm_user_id: ''
  };

  constructor(private quoteService: QuoteService, private route: Router, private routes: ActivatedRoute) {}
  ngAfterViewInit() {
    setTimeout(function() {
      scrollTo(0, -1);
    }, 0);
  }
  ngOnInit() {
    this.routes.queryParams.subscribe(params => {
      this.id = params.id;
      this.type = params.type;
    });
    if (this.id == '' || this.type == '' || this.type === undefined || this.id === undefined) {
      this.route.navigate(['/']);
    } else {
      (this.dicussion.crm_type = this.type),
        this.quoteService.getOppLeadDetailById(this.id, this.type).subscribe(
          (res: any) => {
            if (res.status == '200') {
              this.userDetail = res.data.redux;
            } else {
              this.route.navigate(['/']);
            }
          },
          err => {}
        );
    }
  }

  backToQuotePage() {
    this.route.navigate(['/'], { queryParams: { id: this.id, type: this.type } });
    return false;
  }

  sendDiscussion() {
    if (this.id != '') {
      this.dicussion.crm_user_id = this.id;
      this.quoteService.sendDiscussion(this.dicussion).subscribe(
        (res: any) => {
          if (res.status == '200') {
            this.route.navigate(['thankyou/'], {
              queryParams: { d: 2, id: this.id, type: this.type }
            });
          } else {
            this.errors = res.message;
          }
        },
        err => {}
      );
    }
  }
}
