import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { IncompleteStepComponent } from './incomplete-step/incomplete-step.component';
import { extract } from '@app/core';
const routes: Routes = [
  { path: 'thankyou', component: ThankYouComponent },
  {
    path: 'incomplete/:type/:id',
    component: IncompleteStepComponent,
    data: { title: extract('Incomplete Quote') }
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
