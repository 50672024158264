import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PaymentComponent } from './payment.component';
import { PaymentRoutingModule } from './payment-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { RatingModule } from 'ngx-rating';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [PaymentComponent],
  imports: [
    RatingModule,
    BrowserModule,
    CreditCardDirectivesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaymentRoutingModule,
    DeviceDetectorModule.forRoot(),
    NgxMaskModule.forRoot(),
    Ng2TelInputModule
  ],
  providers: [DatePipe]
})
export class PaymentModule {}
