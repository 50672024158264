import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Quote } from './../../models/quote.model';
import { AppState } from './../../app.state';
import * as QuoteActions from './../../actions/quote.actions';
import { QuoteService } from './../service/quote.service';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'autoquote-fileinfo',
  templateUrl: './fileinfo.component.html',
  styleUrls: ['./fileinfo.component.scss']
})
export class FileinfoComponent implements OnInit {
  autoquote: Quote = <Quote>{};
  step_4: boolean = false;
  assets_root = environment.domain_folder;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  uploadedFiles: any = [];
  response: string;
  isAutoquote = true;
  totalWordCount = 0;
  totalPageCount = 0;
  isWordCountServiceInProgress = false;
  showImageContentTextLabel = false;
  isSyncDone: boolean = false;
  @Output() loader: EventEmitter<any> = new EventEmitter();
  @Input()
  set defaultData(object: any) {
    Object.keys(object).forEach(val => {
      this[val] = object[val];
    });
  }
  @Output() syncToParentData: EventEmitter<any> = new EventEmitter();
  constructor(private quoteService: QuoteService, private store: Store<AppState>, private router: Router) {}
  ngOnInit() {
    this.uploader = new FileUploader({
      url: environment.api_url + '/autoquote/uploadManualFormFiles?key=' + environment.headerKey,
      autoUpload: true
    });
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('crm_id', this.autoquote.crm_id);
      form.append('quote_type', this.autoquote.quote_type);
    };
    this.uploader.onBeforeUploadItem = item => {
      item['uniqueCode'] = this.generateUniqueCode();
      item['wordcount'] = 0;
      item['pagecount'] = 0;
      item['isWord'] = 0;
      item['isPage'] = 0;
      item['price'] = 0;
      item['isSuccess'] = false;
      item['isDeleting'] = false;
      item['isWordCountComplete'] = false;
      item['isWordCountServiceComplete'] = false;
      item.withCredentials = false;
    };
    this.uploader.onAfterAddingFile = item => {
      this.uploader.autoUpload;
    };
    this.uploader.onCompleteItem = item => {};
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.response = '';

    this.uploader.response.subscribe((res: any) => {});
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      let data = JSON.parse(response);
      if (typeof data.file != 'undefined' && data.file[0]['ext'] == 'pdf') {
        this.isWordCountServiceInProgress = true;
        this.quoteService.convertPdfToDoc(data).subscribe(
          res => {
            this.onSuccessItem(item, JSON.stringify(res), status, headers);
          },
          error => {
            this.onSuccessItem(item, null, status, headers);
          }
        );
      } else {
        this.onSuccessItem(item, response, status, headers);
      }
    };
    this.store.select('quote').subscribe(data => {
      this.loader.emit(false);
      this.autoquote = data[0];
      //this.autoquote.step > 3 &&
      if (this.isSyncDone == false) {
        this.syncFilesFromCrm();
      }
    });
  }

  syncFilesFromCrm(forceUpdate = false) {
    let remainingFiles = this.uploader.queue.filter(data => {
      return data['document_id'] == '';
    });
    if (remainingFiles.length > 0 && forceUpdate == false) {
      return false;
    }
    this.quoteService
      .getQuoteAllFiles({
        crm_quote_type: this.autoquote.quote_type,
        crm_id: this.autoquote.crm_id,
        step: this.autoquote.step,
        isCertified: this.autoquote.certifiedTranslation == 'no' ? false : true
      })
      .subscribe(response => {
        if (response.status == 200) {
          this.uploader.queue = this.uploader.queue.filter((file: any) => !file.document_id);
          this.isWordCountServiceInProgress = false;
          this.isSyncDone = true;
          if (this.uploadedFiles.length > 0) {
            this.uploadedFiles.map((item: any) => {
              response.data.files.forEach((file: any) => {
                if (file.id === item.id) {
                  return file;
                }
              });
              response.data.files.forEach((file: any) => {
                if (file.id !== item.id) {
                  return file;
                }
              });
            });
          } else {
            this.uploadedFiles = response.data;
          }
          this.autoquote.quoteFiles = response.data;
          this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
        }
      });
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let data = JSON.parse(response); //success server response
    item['document_id'] = data.file[0]['document_id'];
    item['deleteUrl'] = data.file[0]['deleteUrl'];
  }

  deleteDoc(item: any) {
    item.isDeleting = true;
    this.isWordCountServiceInProgress = true;
    this.quoteService.deleteUploadedDocument(item.deleteUrl).subscribe(data => {
      item.remove();
    });
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
    this.isAutoquote = false;
  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  generateUniqueCode() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }
  goBackToStep() {
    this.autoquote.step = 3;
    this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
  }
  submitFiles() {
    this.autoquote.step = 6;
    this.autoquote.quoteFiles = this.uploader.queue;
    this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
  }
  moveToNextStep() {
    //this.submitQuote(false, true);
  }
  /* submitQuote(isSubmit: boolean, isAutoquote = false) {
    this.loader.emit(true);
    let data = {
      crm_quote_type: this.autoquote.quote_type,
      crm_id: this.autoquote.crm_id,
      isCompleted: isSubmit,
      isAutoquote: isAutoquote,
      embeddedImage: this.uploadedFiles.embeddedImage && this.autoquote.embeddedImage
    };
    this.quoteService.submitQuote(data).subscribe(
      data => {
        this.loader.emit(false);
        if (data.status == '200') {
          if (isSubmit == false) {
            this.autoquote.step = 5;
            this.autoquote.submitted = true;
            this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
          } else {
            this.router.navigate(['thankyou/'], {
              queryParams: { d: 1, id: this.autoquote.crm_id, type: this.autoquote.quote_type }
            });
          }
        }
      },
      error => {
        this.loader.emit(false);
      }
    );
  } */
}
