import { Component, OnInit, AfterContentInit, EventEmitter, Output, Input } from '@angular/core';
import { Quote } from './../../models/quote.model';
import { Store } from '@ngrx/store';
import { AppState } from './../../app.state';
import * as QuoteActions from './../../actions/quote.actions';
import { QuoteService } from './../service/quote.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'quote-history',
  templateUrl: './quote-history.component.html',
  styleUrls: ['./quote-history.component.scss']
})
export class QuoteHistoryComponent implements OnInit, AfterContentInit {
  step_1: boolean = false;
  step_2: boolean = false;
  step_3: boolean = false;
  autoquote: Quote = <Quote>{};
  is_desktop = false;
  assets_root: string;
  history_data: any = { status: '', data: [] };
  eml: string;
  history_check: boolean = false;
  history_records: boolean = false;
  total_item: number;
  start_date: string;
  end_date: string;
  filter_array: any = [];
  show_history: string = '';
  expend_text: string = '';
  filter_status: any = { options: ['All', 'Ongoing', 'Completed'] };
  @Output() loader: EventEmitter<any> = new EventEmitter();
  @Input()
  set defaultData(object: any) {
    Object.keys(object).forEach(val => {
      this[val] = object[val];
    });
  }
  constructor(private quoteService: QuoteService, private store: Store<AppState>, public datepipe: DatePipe) {}

  ngOnInit() {
    this.store.select('quote').subscribe(data => {
      this.loader.emit(false);
      this.autoquote = data[0];
      if (this.eml !== this.autoquote.email) {
        this.eml = this.autoquote.email;
        this.getHistory();
      }
    });
  }
  ngAfterContentInit() {}
  getHistory() {
    if (this.eml && this.eml != '') {
      this.loader.emit(true);
      this.quoteService.getHistory(this.eml).subscribe(
        (res: any) => {
          if (res.status == '200') {
            this.history_records = true;
            this.loader.emit(false);
            this.history_data = res;
            this.total_item = this.history_data.data.length;
          } else {
            this.history_records = false;
            this.loader.emit(false);
          }
        },
        err => {}
      );
    }
  }
  historyDateFilter(historyDateFilter: any) {
    if (historyDateFilter.length > 0) {
      this.start_date = this.datepipe.transform(historyDateFilter[0], 'yyyy-MM-dd');
      this.end_date = this.datepipe.transform(historyDateFilter[1], 'yyyy-MM-dd');
    }
    this.filter_array = [];
    for (var x in this.history_data.data) {
      if (
        this.start_date <= this.history_data.data[x].date_entered &&
        this.end_date >= this.history_data.data[x].date_entered
      ) {
        if (this.show_history == 'Ongoing' || this.show_history == 'All') {
          this.filter_array.push(this.history_data.data[x].id);
        } else {
          if (this.history_data.data[x].filterstatus == this.show_history) {
            this.filter_array.push(this.history_data.data[x].id);
          }
        }
      }
    }
    this.total_item = this.filter_array.length;
    this.history_check = true;
    this.expend_text = 'Minimize';
  }

  historyFilter(flt_status: any) {
    this.show_history = flt_status;
    this.filter_array = [];
    for (var x in this.history_data.data) {
      if (this.start_date === undefined && this.end_date === undefined) {
        if (this.show_history == 'Ongoing' || this.show_history == 'All') {
          this.filter_array.push(this.history_data.data[x].id);
        } else {
          if (this.history_data.data[x].filterstatus == this.show_history) {
            this.filter_array.push(this.history_data.data[x].id);
          }
        }
      } else {
        if (
          this.start_date <= this.history_data.data[x].date_entered &&
          this.end_date >= this.history_data.data[x].date_entered
        ) {
          if (this.show_history == 'Ongoing' || this.show_history == 'All') {
            this.filter_array.push(this.history_data.data[x].id);
          } else {
            if (this.history_data.data[x].filterstatus == this.show_history) {
              this.filter_array.push(this.history_data.data[x].id);
            }
          }
        }
      }
    }
    this.total_item = this.filter_array.length;
    this.history_check = true;
    this.expend_text = 'Minimize';
  }
}
