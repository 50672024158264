import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { QuoteService } from '../home/quote.service';
declare var gtag: (arg0: string, arg1: string, arg2: any) => void;
@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit, AfterViewInit {
  @ViewChild('phonenumber') phonenumber: ElementRef;
  status: number = 0;
  pageDetail = { 1: 'new manual quote submited', 2: 'Request for change', 4: 'renegotiation' };
  show_loader: boolean = true;
  params$: Subscription;
  assets_root: string = environment.domain_folder;
  quote = { id: '', type: '' };
  name: string = '';
  referenceId: string = '';
  project_type: string = '';
  sourceLanguage: any = [];
  targetLanguage: any = [];
  tempTargetLanguage: any = [];
  email: string = '';
  filterstatus: string = '';
  showFiles: boolean = false;
  quoteFiles: any = [];
  isAutoquote: boolean = false;
  notification: any = {
    crm_id: '',
    crm_type: '',
    mode: 'WhatsApp',
    phone_number: ''
  };
  success_message: any = false;
  success_message_desc: string = '';
  show_notification_form = false;
  success_noti_message_desc: string = '';
  notification_error_message: string = '';
  notification_select: any = {
    options: [{ value: 'WhatsApp', name: 'WhatsApp' }, { value: 'SMS', name: 'SMS' }]
  };
  phone_error: any;
  countryCode = '+1';
  constructor(private route: Router, private routes: ActivatedRoute, private quoteService: QuoteService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(function() {
      scrollTo(0, -1);
    }, 0);
    this.params$ = this.routes.queryParams.subscribe(params => {
      this.status = params['d'];
      if (params.id && params.type) {
        this.quote.id = params.id;
        this.quote.type = params.type;
        this.show_loader = true;
        this.quoteService.getOppLeadDetailById(params.id, params.type).subscribe((res: any) => {
          this.show_loader = false;
          if (res != null && res.status == '200') {
            this.referenceId = res.data.redux.referenceId;
            this.project_type = res.data.redux.project_type;
            this.sourceLanguage = res.data.redux.source_language;
            this.targetLanguage = res.data.redux.target_language;
            // this.targetLanguage = this.splitArrayIntoChunksOfLen(this.tempTargetLanguage, 2);
            this.email = res.data.redux.email;
            this.filterstatus = res.data.redux.filterstatus;
            this.isAutoquote = res.data.redux.isAutoquote;
            this.syncFilesFromCrm();
            console.log('converted');
            gtag('event', 'conversion', {
              send_to: 'AW-1058306045/aJNsCJLG09UBEP3v0fgD',
              transaction_id: ''
            });
          } else {
            this.route.navigate(['/']);
          }
        });
      }
    });
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j: any = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-MJ262WW');
  }
  onCountryChange($event: any) {
    this.countryCode = '+' + $event.dialCode;
  }
  notificationSubmit() {
    if (!this.phone_error) {
      return false;
    }
    if (this.notification.phone_number.indexOf('+') === -1) {
      this.notification.phone_number = this.countryCode + this.notification.phone_number;
    }
    if (this.quote.id != '') {
      this.notification.crm_id = this.quote.id;
    }
    if (this.quote.type != '') {
      this.notification.crm_type = this.quote.type;
    }
    this.show_loader = true;
    this.quoteService.updateNotificationSetting(this.notification).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.show_loader = false;
          this.success_message = true;
          this.show_notification_form = false;
          this.success_noti_message_desc = res.message;
        } else {
          this.show_loader = false;
          this.success_message = true;
          this.show_notification_form = true;
          this.notification_error_message = res.message;
        }
      },
      err => {}
    );
  }
  checkKey(event: any) {
    if (event.keyCode === 13) {
      return false;
    }
  }

  hasError(event: any) {
    if (event === true) {
      this.phone_error = true;
    } else {
      this.phone_error = false;
    }
  }
  splitArrayIntoChunksOfLen(arr: [], len: number) {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }
  syncFilesFromCrm() {
    this.quoteService
      .getQuoteAllFiles({
        crm_quote_type: this.quote.type,
        crm_id: this.quote.id,
        isCertified: false
      })
      .subscribe(response => {
        if (response.status == 200) {
          this.quoteFiles = response.data;
        }
      });
  }
  backToQuoteView() {
    this.route.navigate(['/'], { queryParams: { id: this.quote.id, type: this.quote.type } });
    return false;
  }
  backToPendingList() {
    window.location.href = environment.secureapproval_url + '/quotes/pending?eml=' + this.email;
  }
  ngOnDestroy() {
    this.params$.unsubscribe();
  }
}
