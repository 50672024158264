import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Quote } from './../../models/quote.model';
import { AppState } from './../../app.state';
import * as QuoteActions from './../../actions/quote.actions';
import { QuoteService } from './../service/quote.service';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, ValidationErrors } from '@angular/forms';
var global: any;
declare var jQuery: any;
@Component({
  selector: 'autoquote-quoteinfo',
  templateUrl: './quoteinfo.component.html',
  styleUrls: ['./quoteinfo.component.scss'],
  providers: [NgbDatepickerConfig]
})
export class QuoteinfoComponent implements OnInit {
  public formControl: FormControl = new FormControl(null);
  @ViewChild('quoteSecondForm')
  public quoteSecondForm: NgForm;
  @ViewChild('targetLanguage') targetLanguage: ElementRef;
  step_1: boolean = false;
  step_2: boolean = false;
  step_3: boolean = false;
  autoquote: Quote = <Quote>{};
  is_desktop = false;
  assets_root = environment.domain_folder;
  itemList: any = [];
  itemInitialListMultiple: any;
  itemListMultiple: any = [];
  constantItemListMultiple: any = [];
  selectedItems: any;
  settings: any;
  settingMultiple: any;
  minDate: {};
  sourceLanguageKeyInitiated = false;
  targetLanguageKeyInitiated = false;
  languageForAta = [
    'Arabic',
    'Chinese',
    'Croatian',
    'Danish',
    'Dutch',
    'French',
    'German',
    'Italian',
    'Japanese',
    'Polish',
    'Portuguese',
    'Russian',
    'Spanish',
    'Swedish',
    'Ukrainian',
    'English'
  ];
  certifiedTranslations = [
    { value: 'no', text: "No, I don't need certification" },
    { value: 'yes', text: 'Yes, I need certification' }
  ];
  typeOfCertification = [
    { value: '', text: '-- Select --' },
    {
      value: 'cert_free',
      text: 'Certificate of Accuracy by Day Translations (No extra cost)'
    },
    { value: 'ata', text: 'American Translators Association (ATA)', disabled: false },
    {
      value: 'cttic',
      text: 'Canadian Translators, Terminologists and Interpreters Council (CTTIC)'
    },
    {
      value: 'naati',
      text: 'National Accreditation Authority for Translators and Interpreters, Australia (NAATI)'
    },
    {
      value: 'najit',
      text: 'National Association of Judiciary Interpreters & Translators (NAJIT)'
    },
    { value: 'sast_ar', text: 'State appointed sworn translators (Argentina)' },
    { value: 'sast_au', text: 'State appointed sworn translators (Austria)' },
    { value: 'sast_br', text: 'State appointed sworn translators (Brazil)' },
    { value: 'sast_gr', text: 'State appointed sworn translators (Germany)' },
    { value: 'sast_me', text: 'State appointed sworn translators (Mexico)' },
    { value: 'sast_nr', text: 'State appointed sworn translators (Norway)' },
    { value: 'sast_sp', text: 'State appointed sworn translators (Spain)' },
    { value: 'Other', text: 'Others' }
  ];
  fieldOfExpertises = [
    { value: '', text: 'Select expertise/industry (if any)' },
    { value: 'translation_affidavit', text: 'Affidavit' },
    { value: 'translation_business', text: 'Business' },
    { value: 'translation_document', text: 'Documents' },
    { value: 'translation_dmv', text: 'Drivers License' },
    { value: 'translation_transcript', text: 'Educational Transcript' },
    { value: 'translation_email', text: 'Email/Chat' },
    { value: 'translation_filmscript', text: 'Film Scripts' },
    { value: 'translation_financial', text: 'Financial' },
    { value: 'translation_ime', text: 'IME Reports' },
    { value: 'translation_legal', text: 'Legal' },
    { value: 'translation_literary', text: 'Literary' },
    { value: 'translation_localization', text: 'Localization' },
    { value: 'translation_magazine', text: 'Magazine' },
    { value: 'translation_manuals', text: 'Manuals' },
    { value: 'translation_medical', text: 'Medical' },
    { value: 'translation_patents', text: 'Patents' },
    { value: 'translation_rfp', text: 'RFP' },
    { value: 'translation_signage', text: 'Signage' },
    { value: 'translation_technical', text: 'Technical Documents' },
    { value: 'translation_uscis', text: 'USCIS' },
    { value: 'translation_audios', text: 'Audios' }
  ];

  @Input()
  set defaultData(object: any) {
    Object.keys(object).forEach(val => {
      this[val] = object[val];
    });
  }
  @Output() syncToParentData: EventEmitter<any> = new EventEmitter();
  @Output() loader: EventEmitter<any> = new EventEmitter();
  constructor(
    private quoteService: QuoteService,
    private router: Router,
    private store: Store<AppState>,
    public datepipe: DatePipe
  ) {}
  ngOnInit() {
    global = this;
    let date = new Date();
    this.minDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
    this.store.select('quote').subscribe(data => {
      this.loader.emit(false);
      this.autoquote = data[0];
    });
    this.quoteService.getAllLanguages().subscribe((res: any) => {
      if (res.status == '200') {
        res.data.forEach(function(response: any) {
          let modCountryImage = global.assets_root + response.image;
          global.itemList.push({
            id: response.id,
            itemName: response.name,
            move_to_manual: response.move_to_manual,
            capital: response.name,
            //image: modCountryImage,
            is_language_support_ata: response.is_language_support_ata
          });
          global.constantItemListMultiple.push({
            id: response.id,
            itemName: response.name,
            move_to_manual: response.move_to_manual,
            child: JSON.parse(response.child),
            //image: modCountryImage,
            is_language_support_ata: response.is_language_support_ata
          });
        });
        global.onInitialTarget();
        this.onLanguageChange();
      }
    });
    this.selectedItems = [];
    this.settingMultiple = {
      singleSelection: false,
      text: 'Select or enter target language(s)',
      enableSearchFilter: true,
      classes: 'myclass custom-class target_language_selector',
      showCheckbox: true,
      searchPlaceholderText: 'Search Language',
      groupBy: 'category',
      searchBy: ['category'],
      addNewItemOnFilter: true,
      noDataLabel: 'Press Enter to add languages'
    };

    this.settings = {
      enableSearchFilter: true,
      searchPlaceholderText: 'Search Language',
      singleSelection: true,
      text: 'Select or enter any source language',
      addNewItemOnFilter: true,
      noDataLabel: 'Press Enter to add languages'
    };
  }
  nonSupportedLangaugeText: any = '';
  notSupportedAtaLangauge: any = [];
  supportedAtaLanguage: any = [];
  showAtaWarningForcely = false;
  onLanguageChange() {
    this.showAtaWarningForcely = false;
    this.notSupportedAtaLangauge = [];
    this.supportedAtaLanguage = [];
    this.checkIsAutoquote();
    //if (this.autoquote.isAutoquote) {
    const languages = [...this.autoquote.target_language, ...this.autoquote.source_language];
    languages
      .filter(lang => lang.category !== 'English' && lang.itemName !== 'English')
      .forEach((language: any) => {
        if (language.is_language_support_ata === '0') {
          this.notSupportedAtaLangauge.push(language.itemName);
        } else {
          this.supportedAtaLanguage.push(language.itemName);
        }
      });
    if (this.supportedAtaLanguage.length === 0 && languages.length > 0) {
      this.typeOfCertification = this.typeOfCertification.map((data: any) => {
        data.disabled = data.value === 'ata' ? true : false;
        return data;
      });
      if (this.autoquote.typeOfCertifiedTranslation === 'ata') {
        this.autoquote.typeOfCertifiedTranslation = '';
        this.showAtaWarningForcely = true;
      }
    } else {
      this.typeOfCertification = this.typeOfCertification.map((data: any) => {
        data.disabled = false;
        return data;
      });
    }
    //}
    this.nonSupportedLangaugeText = this.notSupportedAtaLangauge.join(', ');
  }
  quoteSecondFormSubmit(isSubmit?: boolean) {
    this.loader.emit(true);
    this.checkIsAutoquote();
    this.quoteService
      .addSecondStepDataToCRM({
        source_language: this.autoquote.source_language,
        crm_id: this.autoquote.crm_id,
        crm_quote_type: this.autoquote.quote_type,
        target_language: this.autoquote.target_language,
        due_date: this.autoquote.due_date ? this.datepipe.transform(this.autoquote.due_date, 'yyyy-MM-dd') : '',
        certifiedTranslation: this.autoquote.certifiedTranslation,
        otherCertificationInput: this.autoquote.otherCertificationInput,
        typeOfCertifiedTranslation: this.autoquote.typeOfCertifiedTranslation,
        proofReadingSelection: this.autoquote.proofReadingSelection,
        isCompleted: isSubmit,
        fieldOfExpertise: this.autoquote.fieldOfExpertise,
        notes: this.autoquote.notes
      })
      .subscribe((res: any) => {
        if (res.status == '200') {
          if (isSubmit !== true) {
            this.autoquote.recentlyAddedNotes = [res.data.note_id];
            this.autoquote.step = 4;
            this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
          } else {
            this.router.navigate(['thankyou/'], {
              queryParams: { d: 1, id: this.autoquote.crm_id, type: this.autoquote.quote_type }
            });
          }
        }
      });
  }
  languageWithoutEnglish: any = [];
  checkIsAutoquote() {
    this.languageWithoutEnglish = [];
    let isManual = true;
    if (this.autoquote.source_language.length == 1) {
      if (this.autoquote.source_language[0].itemName == 'English') {
        isManual = false;
      } else {
        this.languageWithoutEnglish.push(this.autoquote.source_language[0].itemName);
      }
    }
    if (isManual == true) {
      const checkForEngLng = this.autoquote.target_language.filter((data: any) => data.category != 'English');
      if (checkForEngLng.length == 0) {
        this.autoquote.target_language.forEach((language: any) => {
          this.languageWithoutEnglish.push(language.category);
        });
        isManual = false;
      }
    } else {
      const checkForEngLng = this.autoquote.target_language.filter((data: any) => data.category != 'English');
      if (checkForEngLng.length == 0) {
        isManual = true;
      } else {
        this.autoquote.target_language.forEach((language: any) => {
          this.languageWithoutEnglish.push(language.category);
        });
      }
    }
    this.autoquote.isAutoquote = !isManual;
  }

  onCountryChange(event: any) {
    if (typeof event.dialCode != 'undefined') {
      this.autoquote.phone = '+' + event.dialCode + ' ';
    }
  }

  onAddSourceNewItem(data: string) {
    if (data) {
      var key = this.getUniqueID();
      //this.itemList = [];
      this.autoquote.source_language = [];
      this.itemList.push({
        id: key,
        itemName: data,
        capital: data,
        //image: '/assets/language/other-country.svg',
        is_language_support_ata: false
      });
      this.autoquote.source_language.push({
        id: key,
        itemName: data,
        capital: data,
        is_language_support_ata: '0'
        //image: '/assets/language/other-country.svg'
      });
    }
  }
  onAddTargetNewItem(data: string) {
    if (data) {
      const resultFound = this.itemListMultiple.filter((item: any) => item.itemName == data);
      if (resultFound.length == 0) {
        var key = this.getUniqueID();
        this.itemListMultiple.push({
          id: key,
          itemName: data,
          category: data,
          //image: '/assets/language/other-country.svg',
          is_child: true,
          is_language_support_ata: '0'
        });
        this.autoquote.target_language.push({
          id: key,
          itemName: data,
          category: data,
          //image: '/assets/language/other-country.svg',
          is_child: true,
          is_language_support_ata: '0'
        });
        this.onInitialTarget();
      }
    }
  }
  listOpen() {
    if (!this.sourceLanguageKeyInitiated) {
      this.sourceLanguageKeyInitiated = true;
      jQuery('angular2-multiselect[name="source_language"]  .c-input').keydown(function(e: any) {
        jQuery(this).focus();
        if (e.which === 9 || e.which === 13) {
          var isSelected = false;
          var selectedIndex = 0;
          jQuery('angular2-multiselect[name="source_language"] .lazyContainer li').each((index: number, li: any) => {
            if (jQuery(li).hasClass('tab-select') && isSelected === false) {
              isSelected = true;
              let indexSum = index + 2;
              selectedIndex = index;
              if (e.which === 9) {
                jQuery(li).removeClass('tab-select');
                jQuery(
                  'angular2-multiselect[name="source_language"] .lazyContainer li:nth-child(' + indexSum + ')'
                ).addClass('tab-select');
              } else {
                jQuery(li).removeClass('tab-select');
              }
            }
          });
          if (isSelected == false) {
            selectedIndex = 1;
            jQuery('angular2-multiselect[name="source_language"] .lazyContainer li:nth-child(1)').addClass(
              'tab-select'
            );
          }
          if (e.which === 13) {
            if (
              jQuery('angular2-multiselect[name="source_language"] .c-input')
                .val()
                .trim() == ''
            ) {
              global.autoquote.source_language = [];
              global.autoquote.source_language.push(global.itemList[selectedIndex]);
            } else {
              const filterOptions = global.itemList.filter((item: any) => {
                return global.applyFilter(
                  item,
                  jQuery('angular2-multiselect[name="source_language"] .c-input').val(),
                  ''
                );
              });
              if (filterOptions.length > 0) {
                global.autoquote.source_language = [];
                global.autoquote.source_language.push(filterOptions[selectedIndex]);
              } else {
                return true;
              }
            }
          }
          return false;
        }
      });
    }
  }

  applyFilter(item: any, filter: any, searchBy: any) {
    var found = false;
    if (searchBy.length > 0) {
      if (item.grpTitle) {
        found = true;
      } else {
        for (var t = 0; t < searchBy.length; t++) {
          if (filter && item[searchBy[t]] && item[searchBy[t]] != '') {
            if (
              item[searchBy[t]]
                .toString()
                .toLowerCase()
                .indexOf(filter.toLowerCase()) >= 0
            ) {
              found = true;
            }
          }
        }
      }
    } else {
      if (item.grpTitle) {
        found = true;
      } else {
        for (var prop in item) {
          if (filter && item[prop]) {
            if (
              item[prop]
                .toString()
                .toLowerCase()
                .indexOf(filter.toLowerCase()) >= 0
            ) {
              found = true;
            }
          }
        }
      }
    }
    return found;
  }
  onInitialTarget() {
    var lang: any[] = [];
    this.constantItemListMultiple.map(function(data: any) {
      if (data.child.length > 0) {
        data.child.map(function(childData: any) {
          lang.push({
            id: data.id + '_' + childData.value,
            itemName: childData.name,
            //image: data.image,
            is_child: false,
            category: data.itemName,
            is_language_support_ata: data.is_language_support_ata
          });
        });
      } else {
        lang.push({
          id: data.id,
          itemName: data.itemName,
          //image: data.image,
          is_child: true,
          category: data.itemName,
          is_language_support_ata: data.is_language_support_ata
        });
      }
    });
    this.itemInitialListMultiple = lang;
    if (this.autoquote.target_language.length > 0) {
      this.autoquote.target_language.forEach(function(data: any) {
        if (!lang.some((item: any) => item.itemName == data.itemName)) {
          lang.push(data);
        }
      });
    }
    this.itemListMultiple = lang;
    if (!this.targetLanguageKeyInitiated) {
      this.targetLanguageKeyInitiated = true;
      jQuery('.input-checkbox').on('keypress', function(event: any) {
        if (event.which === 13) {
          jQuery(this).prop('checked', !jQuery(this).prop('checked'));
        }
      });
    }
  }
  needCertification() {
    if (this.autoquote.certifiedTranslation === 'yes') {
      this.autoquote.project_type = 'Certified Translation';
    } else {
      this.autoquote.project_type = 'Translation';
    }
    this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
  }
  getUniqueID() {
    return Date.now() + (Math.random() * 100000).toFixed() + '_manual';
  }
  OnTargetLanguageDeSelect(item: any) {
    this.autoquote.target_language = this.autoquote.target_language.filter((data: any) => data.id != item.id);
    this.onLanguageChange();
  }
  clearAllTargetLang() {
    this.autoquote.target_language = [];
  }
  goToStep() {
    window.location.href = environment.autoquote_url;
  }
}
