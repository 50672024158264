import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class QuoteService {
  data: any = [];
  additional_service_selected_id: any;
  additional_service_obj: any;
  _apiURL = environment.api_url;
  applicable_object: any;
  constructor(private httpClient: HttpClient) {}

  addDataToCRM(quoteData: any): Observable<any> {
    let storedQueryParams: any = {};
    try {
      // Retrieve the stored query parameters from localStorage
      const storedQueryParamsString = window.parent.localStorage.getItem('salesInfo');
      // Parse the JSON string back to an object
      storedQueryParams = JSON.parse(storedQueryParamsString);
    } catch (e) {
      console.log('JSON parse error!');
    }
    storedQueryParams = storedQueryParams ? storedQueryParams : {};
    return this.httpClient
      .post(this._apiURL + '/autoquote/addQuoteManualFormData', { ...quoteData, ...storedQueryParams })
      .pipe(tap(data => this.response(data)));
  }
  addAdditionalDataToCRM(data: any): Observable<any> {
    return this.httpClient
      .post(this._apiURL + '/autoquote/addAdditionalDataToCRM', data)
      .pipe(tap(data => this.response(data)));
  }

  updateClientLeavingReason(data: any): Observable<any> {
    let body = new FormData();
    body.append('selection', data.selection);
    body.append('crm_quote_type', data.crm_quote_type);
    body.append('crm_id', data.crm_id);
    return this.httpClient
      .post(this._apiURL + '/autoquote/updateClientLeavingReason', body)
      .pipe(tap(data => this.response(data)));
  }
  downloadFilesFromCRM(id: any, type: any): Observable<any> {
    return this.httpClient
      .get(this._apiURL + `/autoquote/downloadFilesFromCRM/${id}/${type}`)
      .pipe(tap(data => this.response(data)));
  }

  getAdditionServiceObj(): Observable<any> {
    return this.httpClient
      .get(this._apiURL + '/autoquote/getAdditionalServiceJsonObj')
      .pipe(tap(data => this.response(data)));
  }
  addSecondStepDataToCRM(stepFirstData: any): Observable<any> {
    let body = new FormData();
    body.append('source_language', JSON.stringify(stepFirstData.source_language));
    body.append('target_language', JSON.stringify(stepFirstData.target_language));
    body.append('due_date', stepFirstData.due_date);
    body.append('certifiedTranslation', stepFirstData.certifiedTranslation);
    body.append('typeOfCertifiedTranslation', stepFirstData.typeOfCertifiedTranslation);
    body.append('otherCertificationInput', stepFirstData.otherCertificationInput);
    body.append('fieldOfExpertise', stepFirstData.fieldOfExpertise);
    body.append('proofReadingSelection', stepFirstData.proofReadingSelection);
    body.append('notes', stepFirstData.notes);
    body.append('crm_quote_type', stepFirstData.crm_quote_type);
    body.append('crm_id', stepFirstData.crm_id);
    body.append('isCompleted', stepFirstData.isCompleted ? stepFirstData.isCompleted : '');
    return this.httpClient
      .post(this._apiURL + '/autoquote/addSecondStepQuoteFormData', body)
      .pipe(tap(data => this.response(data)));
  }

  submitQuote(data: any): Observable<any> {
    return this.httpClient.post(this._apiURL + '/autoquote/submitQuote', data).pipe(tap(data => this.response(data)));
  }

  saveUpdateNotes(data: any): Observable<any> {
    return this.httpClient
      .post(this._apiURL + '/autoquote/saveUpdateNotes', data)
      .pipe(tap(data => this.response(data)));
  }
  getQuoteAllFiles(data: any): Observable<any> {
    return this.httpClient
      .post(this._apiURL + '/autoquote/getFilesArrayByQuoteId', data)
      .pipe(tap(data => this.response(data)));
  }
  convertPdfToDoc(data: string) {
    return this.httpClient
      .post(this._apiURL + '/autoquote/convertPdfToDoc', data)
      .pipe(tap(data => this.response(data)));
  }
  response(data: any) {
    this.data = data;
  }
  getAllLanguages(): Observable<any> {
    return this.httpClient.get(this._apiURL + '/autoquote/getAllLanguage').pipe(tap(data => this.response(data)));
  }
  getFileWordCount(body: any): Observable<any> {
    return this.httpClient
      .post(this._apiURL + '/autoquote/getFileWordCount', body)
      .pipe(tap(data => this.response(data)));
  }
  deleteUploadedDocument(url: string): Observable<any> {
    return this.httpClient.get(url).pipe(tap(data => this.response(data)));
  }
  getOppLeadDetailById(id: any, type: any): Observable<any> {
    return this.httpClient
      .get(this._apiURL + `/autoquote/getOppLeadDetailById/${id}/${type}`)
      .pipe(tap(data => this.response(data)));
  }
  requestForInvoice(id: any, type: any) {
    return this.httpClient
      .get(this._apiURL + `/approvals/requestForInvoice/${id}/${type}`)
      .pipe(tap(data => this.response(data)));
  }
  getHistory(email: string): Observable<any> {
    return this.httpClient
      .get(this._apiURL + '/approvals/getUserHistory?email=' + email, httpOptions)
      .pipe(tap(data => this.response(data)));
  }
  getTranslateFilesById(data: any): Observable<any> {
    return this.httpClient
      .post(this._apiURL + '/autoquote/getTranslateFilesById', data)
      .pipe(tap(data => this.response(data)));
  }
}
