import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeclineComponent } from './decline/decline.component';
import { RenegotiateComponent } from './renegotiate/renegotiate.component';
import { DeclineRoutingModule } from './decline-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DeclineComponent, RenegotiateComponent],
  imports: [CommonModule, DeclineRoutingModule, FormsModule, ReactiveFormsModule]
})
export class DeclineModule {}
