import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuickRoutingModule } from './quick-routing.module';
import { QuickComponent } from './quick.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxMaskModule } from 'ngx-mask';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FileUploadModule } from 'ng2-file-upload';
import { QuoteService } from './service/quote.service';
import { BasicinfoComponent } from './basicinfo/basicinfo.component';
import { QuoteinfoComponent } from './quoteinfo/quoteinfo.component';
import { FileinfoComponent } from './fileinfo/fileinfo.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HeaderComponent } from './header/header.component';
import { NgxBootstrapSwitchModule } from 'ngx-bootstrap-switch';
import { FormsiteComponent } from './formsite/formsite.component';
import { SafePipe } from './safepipe';
import { ModalModule } from 'ngx-bootstrap';
import { MetaService } from '../shared/meta.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DeviceDetectorModule.forRoot(),
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    QuickRoutingModule,
    Ng2TelInputModule,
    NgxMaskModule,
    AngularMultiSelectModule,
    FileUploadModule,
    NgxBootstrapSwitchModule.forRoot(),
    ModalModule.forRoot()
  ],
  declarations: [
    QuickComponent,
    BasicinfoComponent,
    QuoteinfoComponent,
    FileinfoComponent,
    HeaderComponent,
    FormsiteComponent,
    SafePipe
  ],
  providers: [DatePipe, QuoteService, MetaService]
})
export class QuickModule {}
