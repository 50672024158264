import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'additional-service-suboption',
  templateUrl: './suboption.component.html',
  styleUrls: ['./suboption.component.scss']
})
export class SuboptionComponent implements OnInit, OnChanges {
  @Input() additionOption: any = {};
  @Input() langaugePricingArray = {
    ataSupportedLanguageCount: 0,
    ataNonSupportedLanguageCount: 0,
    ataNonSupportedLanuageText: '',
    totalLanguageCount: 0
  };
  assets_root = environment.domain_folder;
  @Input() additionalModel: any = {};
  @Input() isThisHasSubOption: boolean = false;
  @Input() shipping: any;
  @Input() autoquote: any;
  @Output() additionalSubOptionValueUpdated = new EventEmitter<string>();
  @Output() additionalSubOptionaddInCopy = new EventEmitter<string>();
  @Output() additionalSubOptionremoveInCopy = new EventEmitter<string>();
  @Output() additionalModelOption = new EventEmitter<string>();
  @Output() isSuboptionFormValid = new EventEmitter<boolean>();
  @ViewChild('suboptionForm') suboptionForm: ElementRef;
  @ViewChild('suboptionFormChild') suboptionFormChild: ElementRef;
  isFormValid: boolean = true;
  show_html: any = '';
  statedropdown: any = '';
  pickupLocations: any = {
    options: [
      {
        value: '',
        name: 'Please select your option',
        desc: ''
      },
      {
        value: 'ny',
        name: 'New York, NY',
        desc:
          '<h2>Day Translations, Inc., New York, NY </h2><p>477 Madison Avenue, 6th floor New York, NY 10022</p><br /><p><label>Phone:</label> +(212) 537-6123</p>'
      },
      {
        value: 'il',
        name: 'Chicago, IL',
        desc:
          '<h2>Day Translations, Inc., Chicago, IL</h2><p> 444 N. Michigan Avenue Suite 1200 #45, Chicago, Illinois 60611 </p><br /><p><label>Phone:</label> +(312) 238-8653</p>'
      },
      {
        value: 'fl',
        name: 'Tampa, FL',
        desc:
          '<h2>Day Translations, Inc., Tampa, FL </h2><p>2202 N Westshore Blvd #200, Tampa, FL 33607 </p><br /><p><label>Phone:</label> +813-333-9956</p>'
      },
      {
        value: 'dc',
        name: 'Washington, DC',
        desc: `<h2>Day Translations, Inc., Washington, DC</h2><p> 1629 K St NW, suite 300 Washington, DC 20006 b/t N 17th St & N 16th St </p><br /><p><label>Phone:</label> +(202) 787-3984</p>`
      },
      {
        value: 'tx',
        name: 'Houston, TX',
        desc: `<h2>Day Translations, Inc., Houston, TX </h2><p>118 Vintage Park Blvd. 
          #W716 
          Houston, Tx 77070 
           </p><br /><p><label>Phone:</label> (407) 362-7995</p>`
      },
      {
        value: 'ca',
        name: 'Los Angeles, CA',
        desc: `<h2>Day Translations, Inc., Los Angeles, CA</h2><p> 515 Flower St.,18th Floor
          Los Angeles, CA, 90071 </p><br /><p><label>Phone:</label> +(310) 601-7695</p>`
      },
      {
        value: 'miami',
        name: 'Miami, FL',
        desc: `<h2>Day Translations, Inc., Miami, FL</h2><p> 400 NW 26th St. Suite 3
        Miami, FL 33127 </p><br /><p><label>Phone:</label> +(305) 420-6375</p>`
      },
      {
        value: 'orlando',
        name: 'Orlando, FL',
        desc: `<h2>Day Translations, Inc., Orlando, FL</h2><p> 5401 S. Kirkman Rd. Suite 368 Orlando FL 32819</p><br /><p><label>Phone:</label> +(407) 362-7995</p>`
      },
      {
        value: 'ma',
        name: 'Boston, MA',
        desc: `<h2>Day Translations, Inc., Boston, MA</h2><p> Beacon Hill
          100 Cambridge St                                            
          Suite 1400
          Boston, MA 02114
          </p><br /><p><label>Phone:</label> +1-(617)-395-8949</p>`
      },
      {
        value: 'canada',
        name: 'Toronto, Canada',
        desc: `<h2>Day Translations, Inc., Toronto, Canada</h2><p> 120 Adelaide Street West, Suite 2500
        Mailbox # 329
        Toronto, ON  M5H 1T1
          </p><br /><p><label>Phone:</label> +(1) 4169071099</p>`
      },
      {
        value: 'uae',
        name: 'Dubai, UAE',
        desc: `<h2>Day Translations, Inc., Dubai, UAE</h2><p> JAZFA View 18-19, 1st Floor 
          Jebel Ali Free Zone
          PO Box 262746
          Dubai, UAE </p><br /><p><label>Phone:</label> +8000-3570-4200</p>`
      },
      {
        value: 'sydney',
        name: 'Sydney, Australia',
        desc: `<h2>Day Translations, Inc., Sydney, Australia</h2><p> Level 36, Suite 4, Governor Phillip Tower
        1 Farrer Place, Sydney, NSW 2000 </p><br /><p><label>Phone:</label> +(61) 261084606</p>`
      },
      {
        value: 'israel',
        name: 'Tel Aviv, Israel',
        desc: `<h2>Day Translations, Inc., Tel Aviv, Israel</h2><p>Museum Tower, 4 Berkovich st. (6th floor)
                  Tel Aviv, Israel 6423806 ----
                  POBOX 33111
                  ברקוביץ 4 ת.ד. 33111 תל אביב מיקוד 6133002.
              </p><br /><p><label>Phone:</label> +3-9121867</p>`
      },
      {
        value: 'china',
        name: 'Shanghai, China',
        desc:
          '<h2>Day Translations, Inc., Shanghai, China</h2><p> 15/F L`Avenue, 99 Xianxia Road, Chang Ning District, Shanghai 200051 China </p><br /><p><label>Phone:</label> +86 21 6057-7548</p>'
      },
      {
        value: 'newdelhi',
        name: 'New Delhi, India',
        desc: `<h2>Day Translations, Inc., New Delhi, India</h2><p>A -24/9, Mohan Co-operative, New Delhi -110044, India </p><br /><p><label>Phone:</label> 000-800-100-7131</p>`
      },
      {
        value: 'bengaluru',
        name: 'Bengaluru, India',
        desc: `<h2>Day Translations, Inc., Bengaluru, India</h2><p> DBS House # 26, Cunningham Road, Bangalore 560052</p><br /><p><label>Phone:</label> 000-800-100-7131</p>`
      },
      {
        value: 'japan',
        name: 'Tokyo, Japan',
        desc:
          '<h2>Day Translations, Inc., Tokyo, Japan</h2><p> Level 7, Wakamatsu Building, 3-3-6, Nihonbashi-Honcho, Chuo-Ku, Tokyo 103-0023 </p><br /><p><label>Phone:</label> (81) 3-45789740</p>'
      },
      {
        value: 'france',
        name: 'Paris, France',
        desc:
          '<h2>Day Translations, Inc., Paris, France </h2><p>72 rue du Faubourg, St. Honore, Paris, 75008 </p><br /><p><label>Phone:</label> +33 17663 3289</p>'
      }
    ]
  };
  countriesList = [
    { name: 'Select Country', code: '', value: '' },
    { name: 'Albania', code: 'AL', value: 'Albania' },
    { code: 'AD', name: 'Andorra', value: 'Andorra' },
    { name: 'Antigua and Barbuda', code: 'AG', value: 'Antigua and Barbuda' },
    { name: 'Argentina', code: 'AR', value: 'Argentina' },
    { name: 'Armenia', code: 'AM', value: 'Armenia' },
    { name: 'Australia', code: 'AU', value: 'Australia' },
    { name: 'Austria', code: 'AT', value: 'Austria' },
    { name: 'Azerbaijan', code: 'AZ', value: 'Azerbaijan' },
    { name: 'Bahamas', code: 'BS', value: 'Bahamas' },
    { name: 'Bahrain', code: 'BH', value: 'Bahrain' },
    { name: 'Barbados', code: 'BB', value: 'Barbados' },
    { name: 'Belarus', code: 'BY', value: 'Belarus' },
    { name: 'Belgium', code: 'BE', value: 'Belgium' },
    { name: 'Belize', code: 'BZ', value: 'Belize' },
    { name: 'Bolivia', code: 'BO', value: 'Bolivia' },
    { name: 'Bosnia and Herzegovina', code: 'BA', value: 'Bosnia and Herzegovina' },
    { name: 'Botswana', code: 'BW', value: 'Botswana' },
    { name: 'Brazil', code: 'BR', value: 'Brazil' },
    { name: 'Brunei Darussalam', code: 'BN', value: 'Brunei Darussalam' },
    { name: 'Bulgaria', code: 'BG', value: 'Bulgaria' },
    { name: 'Burundi', code: 'BI', value: 'Burundi' },
    { code: 'CV', name: 'Cabo Verde', value: 'Cabo Verde' },
    { name: 'Chile', code: 'CL', value: 'Chile' },
    { code: 'HK', name: 'China (Hong Kong)', value: 'China (Hong Kong)' },
    { code: 'MO', name: 'China (Macau)', value: 'China (Macau)' },
    { name: 'Colombia', code: 'CO', value: 'Colombia' },
    { name: 'Cook Islands', code: 'CK', value: 'Cook Islands' },
    { name: 'Costa Rica', code: 'CR', value: 'Costa Rica' },
    { name: 'Croatia', code: 'HR', value: 'Croatia' },
    { name: 'Cyprus', code: 'CY', value: 'Cyprus' },
    { name: 'Czech Republic', code: 'CZ', value: 'Czech Republic' },
    { name: 'Denmark', code: 'DK', value: 'Denmark' },
    { name: 'Dominica', code: 'DM', value: 'Dominica' },
    { name: 'Dominican Republic', code: 'DO', value: 'Dominican Republic' },
    { name: 'Ecuador', code: 'EC', value: 'Ecuador' },
    { name: 'El Salvador', code: 'SV', value: 'El Salvador' },
    { name: 'Estonia', code: 'EE', value: 'Estonia' },
    { code: 'SZ', name: 'Eswatini (formerly Swaziland)', value: 'Eswatini (formerly Swaziland)' },
    { name: 'Fiji', code: 'FJ', value: 'Fiji' },
    { name: 'Finland', code: 'FI', value: 'Finland' },
    { name: 'France', code: 'FR', value: 'France' },
    { name: 'Georgia', code: 'GE', value: 'Georgia' },
    { name: 'Germany', code: 'DE', value: 'Germany' },
    { name: 'Greece', code: 'GR', value: 'Greece' },
    { name: 'Grenada', code: 'GD', value: 'Grenada' },
    { name: 'Guatemala', code: 'GT', value: 'Guatemala' },
    { name: 'Guyana', code: 'GY', value: 'Guyana' },
    { name: 'Honduras', code: 'HN', value: 'Honduras' },
    { name: 'Hungary', code: 'HU', value: 'Hungary' },
    { name: 'Iceland', code: 'IS', value: 'Iceland' },
    { name: 'India', code: 'IN', value: 'India' },
    { name: 'Ireland', code: 'IE', value: 'Ireland' },
    { name: 'Israel', code: 'IL', value: 'Israel' },
    { name: 'Italy', code: 'IT', value: 'Italy' },
    { name: 'Japan', code: 'JP', value: 'Japan' },
    { name: 'Kazakhstan', code: 'KZ', value: 'Kazakhstan' },
    { name: 'Korea, Republic of', code: 'KR', value: 'Korea, Republic of' },
    { code: 'XK', name: 'Kosovo', value: 'Kosovo' },
    { name: 'Kyrgyzstan', code: 'KG', value: 'Kyrgyzstan' },
    { name: 'Latvia', code: 'LV', value: 'Latvia' },
    { name: 'Lesotho', code: 'LS', value: 'Lesotho' },
    { name: 'Liberia', code: 'LR', value: 'Liberia' },
    { name: 'Liechtenstein', code: 'LI', value: 'Liechtenstein' },
    { name: 'Lithuania', code: 'LT', value: 'Lithuania' },
    { name: 'Luxembourg', code: 'LU', value: 'Luxembourg' },
    { name: 'Malawi', code: 'MW', value: 'Malawi' },
    { name: 'Malta', code: 'MT', value: 'Malta' },
    { name: 'Marshall Islands', code: 'MH', value: 'Marshall Islands' },
    { name: 'Mauritius', code: 'MU', value: 'Mauritius' },
    { name: 'Mexico', code: 'MX', value: 'Mexico' },
    { name: 'Monaco', code: 'MC', value: 'Monaco' },
    { name: 'Mongolia', code: 'MN', value: 'Mongolia' },
    { code: 'ME', name: 'Montenegro', value: 'Montenegro' },
    { name: 'Morocco', code: 'MA', value: 'Morocco' },
    { name: 'Namibia', code: 'NA', value: 'Namibia' },
    { name: 'Netherlands', code: 'NL', value: 'Netherlands' },
    { name: 'New Zealand', code: 'NZ', value: 'New Zealand' },
    { name: 'Nicaragua', code: 'NI', value: 'Nicaragua' },
    { name: 'Niue', code: 'NU', value: 'Niue' },
    { name: 'Norway', code: 'NO', value: 'Norway' },
    { name: 'Oman', code: 'OM', value: 'Oman' },
    { name: 'Palau', code: 'PW', value: 'Palau' },
    { name: 'Panama', code: 'PA', value: 'Panama' },
    { name: 'Paraguay', code: 'PY', value: 'Paraguay' },
    { name: 'Peru', code: 'PE', value: 'Peru' },
    { name: 'Philippines', code: 'PH', value: 'Philippines' },
    { name: 'Poland', code: 'PL', value: 'Poland' },
    { name: 'Portugal', code: 'PT', value: 'Portugal' },
    { code: 'MD', name: 'Republic of Moldova', value: 'Republic of Moldova' },
    { code: 'MK', name: 'Republic of North Macedonia', value: 'Republic of North Macedonia' },
    { name: 'Romania', code: 'RO', value: 'Romania' },
    { name: 'Russian Federation', code: 'RU', value: 'Russian Federation' },
    { name: 'Saint Kitts and Nevis', code: 'KN', value: 'Saint Kitts and Nevis' },
    { name: 'Saint Lucia', code: 'LC', value: 'Saint Lucia' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC', value: 'Saint Vincent and the Grenadines' },
    { name: 'Samoa', code: 'WS', value: 'Samoa' },
    { name: 'San Marino', code: 'SM', value: 'San Marino' },
    { name: 'Sao Tome and Principe', code: 'ST', value: 'Sao Tome and Principe' },
    { code: 'RS', name: 'Serbia', value: 'Serbia' },
    { name: 'Seychelles', code: 'SC', value: 'Seychelles' },
    { name: 'Slovakia', code: 'SK', value: 'Slovakia' },
    { name: 'Slovenia', code: 'SI', value: 'Slovenia' },
    { name: 'South Africa', code: 'ZA', value: 'South Africa' },
    { name: 'Spain', code: 'ES', value: 'Spain' },
    { name: 'Suriname', code: 'SR', value: 'Suriname' },
    { name: 'Sweden', code: 'SE', value: 'Sweden' },
    { name: 'Switzerland', code: 'CH', value: 'Switzerland' },
    { name: 'Tajikistan', code: 'TJ', value: 'Tajikistan' },
    { name: 'Tonga', code: 'TO', value: 'Tonga' },
    { name: 'Trinidad and Tobago', code: 'TT', value: 'Trinidad and Tobago' },
    { name: 'Tunisia', code: 'TN', value: 'Tunisia' },
    { name: 'Turkey', code: 'TR', value: 'Turkey' },
    { name: 'Ukraine', code: 'UA', value: 'Ukraine' },
    { name: 'United Kingdom', code: 'GB', value: 'United Kingdom' },
    { code: 'US', name: 'United States of America', value: 'United States of America' },
    { name: 'Uruguay', code: 'UY', value: 'Uruguay' },
    { name: 'Uzbekistan', code: 'UZ', value: 'Uzbekistan' },
    { name: 'Vanuatu', code: 'VU', value: 'Vanuatu' },
    { name: 'Venezuela', code: 'VE', value: 'Venezuela' }
  ];

  settingMultiple = {
    singleSelection: false,
    text: 'Select or enter target language(s)',
    enableSearchFilter: false,
    classes: 'myclass custom-class target_language_selector',
    showCheckbox: true,
    searchPlaceholderText: 'Search Language',
    groupBy: 'category',
    searchBy: ['category'],
    addNewItemOnFilter: false
  };
  suboptionLanguages: any = [];
  constructor() {}

  ngOnInit() {}
  ngOnChanges() {
    this.isSuboptionFormValid.emit(this.suboptionForm['valid']);
  }

  isAllFormValid() {
    return this.suboptionFormChild
      ? this.suboptionForm['valid'] && this.suboptionFormChild['isAllFormValid']()
      : this.suboptionForm['valid'];
  }
  additionalOptionValueUpdated($event: any) {
    this.isFormValid = $event;
    this.isSuboptionFormValid.emit(this.isFormValid);
    this.additionalModelOption.emit(this.additionalModel);
    this.additionalSubOptionValueUpdated.emit();
  }
  addInCopy(suboption: any, language: any) {
    this.addLanguageInCopy(language);
    this.emitCountValue(suboption);
    //this.additionalSubOptionaddInCopy.emit(key);
  }
  removeLanguageInCopy(language: any) {
    if (isNaN(language.countValue) || language.countValue <= 0) {
      language.countValue = 1;
    }
    if (language.countValue > 1) {
      language.countValue = parseInt(language.countValue) - 1;
    }
  }
  addLanguageInCopy(language: any) {
    if (isNaN(language.countValue) || language.countValue <= 0) {
      language.countValue = 1;
    }
    language.countValue = parseInt(language.countValue) + 1;
  }
  removeInCopy(suboption: any, language: any) {
    this.removeLanguageInCopy(language);
    this.emitCountValue(suboption);
  }
  deleteLanguage(lang: any, suboption: any) {
    suboption.additionalServiceOption.selectedLanguage = suboption.additionalServiceOption.selectedLanguage.filter(
      (language: any) => language.id !== lang.id
    );
    this.emitCountValue(suboption);
  }

  itHasSubOption() {
    this.isThisHasSubOption = true;
  }
  onLanguageChange($event: any, suboption: any) {
    this.emitCountValue(suboption);
  }
  emitCountValue(suboption: any) {
    let totalCount: any = 0;
    suboption.additionalServiceOption.selectedLanguage.forEach((language: any) => {
      if (suboption.value !== 'ata' || (suboption.value === 'ata' && language.is_language_support_ata == '1')) {
        totalCount += language.countValue;
      }
    });
    this.additionalModel[suboption.additionalServiceOption.copyKey] = totalCount;
    this.additionalSubOptionValueUpdated.emit();
  }
  itemInitialListMultiple: any = [];
  onInitialTarget($event: any, suboption: any) {
    var lang: any[] = [];
    const languages = [...this.autoquote.target_language, ...this.autoquote.source_language];
    languages
      .filter(lang => lang.category !== 'English' && lang.itemName !== 'English')
      .map((data: any) => {
        if (suboption.value !== 'ata' || (suboption.value === 'ata' && data.is_language_support_ata == '1')) {
          let modCountryImage = this.assets_root + data.image;
          lang.push({
            id: data.id,
            itemName: data.is_child === false ? data.category + ' (' + data.itemName + ')' : data.itemName,
            image: modCountryImage,
            countValue: 1,
            is_language_support_ata: data.is_language_support_ata,
            is_child: true,
            category: data.is_child === false ? data.category + ' (' + data.itemName + ')' : data.itemName
          });
        }
      });
    this.itemInitialListMultiple = lang;
  }
  isSuboptionFormValChange($event: any) {
    this.isFormValid = $event;
    this.isSuboptionFormValid.emit(this.isFormValid);
  }
  pickupLocationsFun(selected_val: string) {
    if (selected_val == '') {
      this.show_html = '';
    } else {
      for (var x in this.pickupLocations.options) {
        if (this.pickupLocations.options[x].value == selected_val) {
          this.show_html = this.pickupLocations.options[x].desc;
        }
      }
    }
  }
}
