import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuoteService } from './../../../service/quote.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})
export class CompletedComponent implements OnInit {
  @Input() autoquote: any = {};
  @Input() sidebar: any = {};
  @Input() assets_root: string = environment.domain_folder;
  @Input() isServiceListMinimized: boolean = true;
  @Input() discountApplied: boolean = false;
  @Input() is_desktop: boolean = true;
  @Input() isMailingServiceListMinimized: boolean = true;
  showFiles: boolean = false;
  @Output() downloadFiles = new EventEmitter<string>();
  @Output() deleteAdditionalService = new EventEmitter<number>();
  @Output() deleteAdditionalServiceChild = new EventEmitter<{}>();
  @Output() deleteMailingService = new EventEmitter<number>();
  @Output() deleteMailingServiceChild = new EventEmitter<{}>();
  @Output() isServiceListMinimizedChanged = new EventEmitter<boolean>();
  @Output() isMailingServiceListMinimizedChanged = new EventEmitter<boolean>();
  @Output() clientRequestForInvoice = new EventEmitter<boolean>();
  translateFiles: any = [];
  constructor(private quoteService: QuoteService, private router: Router) {}

  ngOnInit() {
    /* this.quoteService
      .getTranslateFilesById({
        crm_quote_type: this.autoquote.quote_type,
        crm_id: this.autoquote.crm_id
      })
      .subscribe(response => {
        //if (isArray(response)) {
        if (response.length > 0) {
          this.translateFiles = response;
          this.translateFiles.forEach((translateFile: any) => {
            translateFile.document_name = this.getShortName(translateFile.document_name, 20);
          });
        }
        //}
      }); */
  }
  downloadFile() {
    this.downloadFiles.emit();
  }
  removeAdditionalService(index: number) {
    this.deleteAdditionalService.emit(index);
  }
  removeAdditionalServiceChild(parent: any, child: any) {
    this.deleteAdditionalServiceChild.emit({ parent, child });
  }
  removeMailingService(index: number) {
    this.deleteMailingService.emit(index);
  }
  removeMailingServiceChild(parent: any, child: any) {
    this.deleteMailingServiceChild.emit({ parent, child });
  }
  checkNumber(value: any) {
    return !isNaN(value);
  }
  discussionPage() {
    this.router.navigate(['discussion/'], {
      queryParams: { id: this.autoquote.crm_id, type: this.autoquote.quote_type }
    });
    return false;
  }
  requestForInvoice() {
    this.clientRequestForInvoice.emit();
  }

  /*
    return a dotted name if the name length is greater than 15 characters
    eg:
    edit_test_request_output_parameter_checkbox.PNG -> edit_tes....PNG
    edit_test_x.PNG -> edit_test_x.PNG

  */
  getShortName(name: string, maxSizeAllowed = 50) {
    if (name == null) {
      return '';
    }
    const dot = '...';
    const dotLength = dot.length;

    const nameLength = name.length;
    const pointIndex = name.lastIndexOf('.');

    const ext = name.slice(pointIndex, nameLength);
    const extLength = ext.length;

    let shortName = name;

    if (nameLength > maxSizeAllowed) {
      const end = maxSizeAllowed - (dotLength + extLength);
      shortName = `${name.slice(0, end)}${dot}${ext}`;
      return shortName;
    }
    return shortName;
  }
}
