import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { environment } from '@env/environment';
@Component({
  selector: 'autoquote-formsite',
  templateUrl: './formsite.component.html',
  styleUrls: ['./formsite.component.scss']
})
export class FormsiteComponent implements OnInit, OnDestroy {
  assets_root = environment.domain_folder;
  show_loader = false;
  @Input() selectedOption: string = '';
  is_desktop = false;
  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {}
  ngOnDestroy() {}
}
