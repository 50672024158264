import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Quote } from './../../models/quote.model';
import { AppState } from './../../app.state';
import * as QuoteActions from './../../actions/quote.actions';
import { QuoteService } from './../service/quote.service';
import { NgForm } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'autoquote-basicinfo',
  templateUrl: './basicinfo.component.html',
  styleUrls: ['./basicinfo.component.scss']
})
export class BasicinfoComponent implements OnInit, AfterViewInit {
  autoquote: Quote = <Quote>{};
  is_desktop = false;
  assets_root: string;
  form_panel_label: string = '';
  phone_error: boolean = false;
  step_1: boolean = false;
  step_2: boolean = false;
  @Input() showIframe: boolean = false;
  @Input() selectedOption: {};
  @ViewChild('quoteForm')
  public quoteForm: NgForm;
  @Input()
  set defaultData(object: any) {
    Object.keys(object).forEach(val => {
      this[val] = object[val];
    });
  }
  @Output() syncToParentData: EventEmitter<any> = new EventEmitter();
  @Output() loader: EventEmitter<any> = new EventEmitter();
  constructor(private quoteService: QuoteService, private router: Router, private store: Store<AppState>) {}
  ngOnInit() {
    this.store.select('quote').subscribe(data => {
      this.loader.emit(false);
      this.autoquote = data[0];
    });
  }
  quoteFormSubmit() {
    this.autoquote.isSubmitted = true;
    this.loader.emit(true);
    this.quoteService
      .addDataToCRM({
        first_name: this.autoquote.first_name,
        last_name: this.autoquote.last_name,
        jobTitle: this.autoquote.jobTitle,
        email: this.autoquote.email,
        phone: this.autoquote.phone,
        company_name: this.autoquote.company_name,
        certifiedTranslation: this.autoquote.isCertifiedTranslation
      })
      .subscribe(
        (res: any) => {
          if (res.status == '200') {
            this.autoquote.step = 3;
            this.autoquote.referenceId = res.data.reference_number_c;
            this.autoquote.quote_type = res.data.type;
            this.autoquote.crm_id = res.data.id;
            this.autoquote.initialProcess = true;
            this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
            this.router.navigate(['/'], { queryParams: { id: res.data.id, type: res.data.type } });
          }
        },
        err => {}
      );
  }
  goToStep() {
    this['step_1'] = false;
    this['step_2'] = true;
    this.syncToParentData.emit({ step_1: this['step_1'], step_2: this['step_2'] });
  }
  hasError(event: any) {
    if (event === true) {
      this.phone_error = true;
    } else {
      this.phone_error = false;
    }
  }
  onCountryChange() {}
  ngAfterViewInit() {
    $('.flag-container').on('click', function() {
      ['cu', 'kp', 'ir', 'sd', 'sy'].forEach(data => {
        $("li[data-country-code='" + data + "']").remove();
      });
    });
    $(document).ready(function() {
      if (typeof $('.quote-slider').owlCarousel == 'function') {
        $('.quote-slider').owlCarousel({
          loop: true,
          margin: 25,
          nav: true,
          navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
          responsive: {
            0: {
              startPosition: 1,
              items: 3,
              center: true,
              margin: 10
            },
            768: {
              startPosition: 1,
              items: 4,
              center: true,
              margin: 10
            },
            1025: {
              items: 5,
              startPosition: 1,
              center: true
            }
          }
        });
      }
    });
  }
}
