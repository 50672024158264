import { Action } from '@ngrx/store';
import { Quote } from './../models/quote.model';
import * as QuoteActions from './../actions/quote.actions';

// initial state
const initialState: Quote = {
  referenceId: '--',
  form_panel_label: '--',
  crm_id: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  company_name: '',
  notes: '',
  isSubmitted: false,
  certifiedTranslation: 'no',
  due_date: '',
  fieldOfExpertise: '',
  target_language: [],
  source_language: [],
  typeOfCertifiedTranslation: '',
  quote_type: '',
  quoteFiles: {},
  step: 1,
  project_type: '--',
  embeddedImage: false,
  amount: 0,
  additional_words_cost: '',
  additional_words: '',
  additional_pages: '',
  additional_pages_cost: '',
  additionalServices: [],
  otherCertificationInput: '',
  isCertifiedTranslation: 'no',
  created_date: '',
  isAutoquote: true,
  allNotes: [],
  initialProcess: true,
  recentlyAddedNotes: [],
  direct: false,
  status: '',
  isRushfeeAdded: false,
  isFileenggAdded: false,
  rushfeeCost: 0,
  fileenggCost: 0,
  additional_services_cost: 0,
  additional_cost: 0,
  amount_paid: 0,
  amount_pending: 0,
  total_amount_sum: 0,
  totalSideBarAmount: 0,
  selectedAdditionalServiceJson: {},
  isRevisedQuote: false,
  proofReadingSelection: false,
  total_word_count: 0,
  total_page_count: 0,
  revisedQuoteText: '',
  wordPricingArray: [],
  pagePricingArray: [],
  submitted: true,
  applicable_text: '',
  jobTitle: '',
  discount_code: ''
};

// Reducer function
export function quoteReducer(state: Quote[] = [initialState], action: QuoteActions.Actions) {
  // conditional actions
  switch (action.type) {
    case QuoteActions.ADD_UPDATE_QUOTE:
      return [action.payload];
    default:
      return state;
  }
}
