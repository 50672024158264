import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { AutoquoteModule } from './autoquote/autoquote.module';
import { ShellModule } from './shell/shell.module';
import { DeclineModule } from './decline/decline.module';
import { PaymentModule } from './payment/payment.module';
import { PaymentV2Module } from './payment-v2/payment.module';
import { DiscussionModule } from './discussion/discussion.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { quoteReducer } from './reducers/quote.reducer';
import { resultReducer } from './reducers/resultbar.reducer';
import { HeaderComponent } from './shell/header/header.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './shared/confirmation-dialog/confirmation-dialog.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuoteService } from './home/quote.service';
import { IncompleteStepComponent } from './incomplete-step/incomplete-step.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    SharedModule,
    ShellModule,
    AutoquoteModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2TelInputModule,
    PaymentModule,
    PaymentV2Module,
    DeclineModule,
    DiscussionModule,
    NgbModule,
    BrowserAnimationsModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    StoreModule.forRoot({
      quote: quoteReducer,
      result: resultReducer
    })
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    ThankYouComponent,
    ConfirmationDialogComponent,
    IncompleteStepComponent
  ],
  providers: [ConfirmationDialogService, QuoteService],
  entryComponents: [ConfirmationDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
