import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Quote } from './../../models/quote.model';
import { AppState } from './../../app.state';
import * as QuoteActions from './../../actions/quote.actions';
import { QuoteService } from './../service/quote.service';
import { ConfirmationDialogService } from './../../shared/confirmation-dialog/confirmation-dialog.service';
declare var $: any;
var self: any;
@Component({
  selector: 'autoquote-additionalservices',
  templateUrl: './additionalservices.component.html',
  styleUrls: ['./additionalservices.component.scss']
})
export class AdditionalservicesComponent implements OnInit, AfterViewInit {
  @ViewChild('sidebar') sidebar: any;
  autoquote: Quote = <Quote>{};
  step_5: boolean = false;
  quotes: any = {};
  data = {};
  showFiles: boolean = false;
  is_desktop = false;
  assets_root = environment.domain_folder;
  form_panel_label: string = '';
  phone_error: boolean = false;
  isSyncDone = false;
  discountApplied: boolean = false;
  isServiceListMinimized: boolean = true;
  isMailingServiceListMinimized: boolean = true;
  disableMailingWarning: boolean = false;
  description: string = '';
  noteEditId: string = '';
  showAddNote: boolean = false;
  wordPricingArray: string[] = [];
  pagePricingArray: string[] = [];
  @Input()
  set defaultData(object: any) {
    Object.keys(object).forEach(val => {
      this[val] = object[val];
    });
  }
  @Output() syncToParentData: EventEmitter<any> = new EventEmitter();
  @Output() loader: EventEmitter<any> = new EventEmitter();
  constructor(
    private quoteService: QuoteService,
    private router: Router,
    private store: Store<AppState>,
    private confirmationDialogService: ConfirmationDialogService
  ) {}
  ngOnInit() {
    self = this;
    this.store.select('quote').subscribe(data => {
      this.autoquote = data[0];
      if (this.autoquote.step == 5 && this.isSyncDone == false) {
        this.syncFormData();
      }
    });
  }
  ngAfterViewInit() {}
  clientRequestForInvoice() {
    this.quoteService.requestForInvoice(this.autoquote.crm_id, this.autoquote.quote_type).subscribe((res: any) => {});
  }
  redirectToRenegotiatePage() {
    this.router.navigate(['renegotiate/'], {
      queryParams: { id: this.autoquote.crm_id, type: this.autoquote.quote_type }
    });
  }

  discussionPage() {
    this.router.navigate(['discussion/'], {
      queryParams: { id: this.autoquote.crm_id, type: this.autoquote.quote_type }
    });
    return false;
  }
  syncFormData() {
    this.syncFilesFromCrm();
    this.quoteService.getOppLeadDetailById(this.autoquote.crm_id, this.autoquote.quote_type).subscribe((res: any) => {
      if (res != null && res.status == 200) {
        this.loader.emit(false);
        this.isSyncDone = true;
        this.wordPricingArray = res.data.languageArrayWord;
        this.pagePricingArray = res.data.languageArrayPage;
        Object.keys(res.data.redux).forEach((val: any, index: any) => {
          if ('due_date' == val && res.data.redux[val] != '') {
            this.autoquote[val] = new Date(res.data.redux[val]);
          } else {
            this.autoquote[val] = res.data.redux[val];
          }
        });
        if (this.autoquote.step == 5 && this.autoquote.status == '') {
          this.router.navigate(['/']);
        }
        if (this.autoquote.selectedAdditionalServiceJson.additionalModel) {
          if (Object.keys(this.autoquote.selectedAdditionalServiceJson.additionalModel).length > 0) {
            this.sidebar.additionalModel = this.autoquote.selectedAdditionalServiceJson.additionalModel;
          }
        }
        this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
      } else {
        this.router.navigate(['/']);
      }
    });
  }
  cancelNote() {
    this.noteEditId = '';
    this.description = '';
  }

  declinePage() {
    this.router.navigate(['decline/'], { queryParams: { id: this.autoquote.crm_id, type: this.autoquote.quote_type } });
    return false;
  }
  saveNote() {
    if (this.description != '') {
      this.quoteService
        .saveUpdateNotes({
          note: this.description,
          crm_id: this.autoquote.crm_id,
          quote_type: this.autoquote.quote_type,
          note_id: this.noteEditId
        })
        .subscribe((res: any) => {
          this.cancelNote();
          this.autoquote.allNotes = res.data;
          if (res.note_id) {
            this.autoquote.recentlyAddedNotes.push(res.note_id);
          }
          this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
        });
    }
  }

  goBackToStep() {
    this.autoquote.step = 4;
    this.isSyncDone = false;
    this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
  }
  syncFilesFromCrm() {
    this.quoteService
      .getQuoteAllFiles({
        crm_quote_type: this.autoquote.quote_type,
        crm_id: this.autoquote.crm_id,
        step: this.autoquote.step,
        isCertified: this.autoquote.certifiedTranslation == 'no' ? false : true
      })
      .subscribe(response => {
        if (response.status == 200) {
          this.isSyncDone = true;
          this.autoquote.quoteFiles = response.data;
        }
      });
  }
  submitQuote(isSubmit: boolean) {}
  checkForMailingOption() {
    let mailingSeleted: boolean = false;
    let showMailingErrorPopup: boolean = false;
    if (this.sidebar['additionalMailingServiceFinalObj'].length > 0) {
      mailingSeleted =
        this.sidebar['additionalMailingServiceFinalObj'].filter((mailAddtionalService: any) => {
          return mailAddtionalService.mainamount > 0;
        }).length > 0;
    }
    if (this.sidebar['additionalServiceFinalObj'].length > 0 && mailingSeleted == false) {
      showMailingErrorPopup =
        this.sidebar['additionalServiceFinalObj'].filter((addtionalService: any) => {
          let sum = addtionalService.mainamount;
          addtionalService.childs.forEach((child: any) => {
            sum += parseInt(child.amount);
          });
          return (
            (addtionalService.mainkey === 'apistille-notarization' || addtionalService.mainkey === 'certification') &&
            sum > 0
          );
        }).length > 0;
    }
    return showMailingErrorPopup;
  }
  downloadFiles() {
    this.loader.emit(true);
    this.quoteService.downloadFilesFromCRM(this.autoquote.crm_id, this.autoquote.quote_type).subscribe((res: any) => {
      if (res.status == '200') {
        window.open(res.downloadUrl, '_blank');
      }
      this.loader.emit(false);
    });
  }
  isPayButtonDisabled() {
    if (this.sidebar) {
      return (
        this.autoquote.amount_pending +
          parseFloat(this.sidebar.totalSidebarAmount) +
          parseFloat(this.sidebar.totalMailingSidebarAmount) ==
        0
      );
    }
  }
  moveToNextStep() {
    if (this.checkForMailingOption() && this.disableMailingWarning == false) {
      this.confirmationDialogService
        .confirm(
          'Mailing Option',
          "When you choose any additional service (Notarization, Apostille or Certificate of Accuracy), we highly recommend that you select a mailing option for a physical copy of your certificate to be delivered at your address. If you only need an electronic version, it's available at no extra cost.",
          true,
          'Choose Mailing',
          "I Don't Need Mailing",
          'sm',
          './assets/img/mailing-option-icon.svg'
        )
        .then((confirmed: any) => {
          if (confirmed) {
            $('.ad-serivces-all li  a').removeClass('current');
            $('.tab-content-side').removeClass('current');
            $('#tab-4').addClass('current');
          } else {
            this.disableMailingWarning = true;
          }
        })
        .catch(() => {
          $('.ad-serivces-all li  a').removeClass('current');
          $('.tab-content-side').removeClass('current');
          $('#tab-4').addClass('current');
        });
    } else {
      if (
        parseFloat(this.autoquote.amount_pending) +
          parseFloat(this.sidebar.totalSidebarAmount) +
          parseFloat(this.sidebar.totalMailingSidebarAmount) >
        0
      ) {
        this.autoquote.totalSideBarAmount =
          parseFloat(this.sidebar.totalSidebarAmount) + parseFloat(this.sidebar.totalMailingSidebarAmount);
        this.autoquote.step = 6;
        this.autoquote.selectedAdditionalServiceJson = {
          additionalServiceFinalObj: this.sidebar['additionalServiceFinalObj'],
          additionalMailingServiceFinalObj: this.sidebar['additionalMailingServiceFinalObj'],
          additionalModel: this.sidebar['additionalModel'],
          totalSidebarAmount: this.sidebar['totalSidebarAmount'],
          totalMailingSidebarAmount: this.sidebar['totalMailingSidebarAmount'],
          id: this.autoquote.crm_id,
          type: this.autoquote.quote_type
        };
        this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
        /* if (parseFloat(this.sidebar.totalSidebarAmount) + parseFloat(this.sidebar.totalMailingSidebarAmount) > 0) {
            this.quoteService
              .addAdditionalDataToCRM({
                additionalServiceFinalObj: this.sidebar['additionalServiceFinalObj'],
                additionalMailingServiceFinalObj: this.sidebar['additionalMailingServiceFinalObj'],
                additionalModel: this.sidebar['additionalModel'],
                totalSidebarAmount: this.sidebar['totalSidebarAmount'],
                totalMailingSidebarAmount: this.sidebar['totalMailingSidebarAmount'],
                id: this.autoquote.crm_id,
                type: this.autoquote.quote_type
              })
              .subscribe((res: any) => {
                if (res.status == '200') {
                  this.autoquote.step = 6;
                  this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
                  this.router.navigate(['payment/'], {
                    queryParams: { id: this.autoquote.crm_id, type: this.autoquote.quote_type }
                  });
                }
              });
          } else { */
        this.router.navigate(['payment/'], {
          queryParams: { id: this.autoquote.crm_id, type: this.autoquote.quote_type }
        });
        /* } */
      }
    }
  }
  removeAdditionalService(index: number) {
    this.sidebar['additionalServiceFinalObj'][index].childs.forEach((child: any, i: number) => {
      this.removeAdditionalServiceChild(index, i);
    });
    delete this.sidebar['additionalModel'][this.sidebar['additionalServiceFinalObj'][index]['mainkey']];
    this.sidebar['additionalServiceFinalObj'].splice(index, 1);
    self.sidebar.additionalOptionValueUpdated(self.sidebar.shipping, self.sidebar.additionalServiceInfo, false);
  }
  removeAdditionalServiceChild(parentindex: number, index: number) {
    if (this.sidebar['additionalServiceFinalObj'][parentindex]['childs'][index]['isCopy'] === true) {
      delete this.sidebar['additionalModel'][
        this.sidebar['additionalServiceFinalObj'][parentindex]['childs'][index]['additionKey']
      ];
    }
    delete this.sidebar['additionalModel'][
      this.sidebar['additionalServiceFinalObj'][parentindex]['childs'][index]['key']
    ];
    this.sidebar['additionalServiceFinalObj'][parentindex]['childs'].splice(index, 1);
    self.sidebar.additionalOptionValueUpdated(self.sidebar.shipping, self.sidebar.additionalServiceInfo, false);
  }
  removeMailingService(index: number) {
    this.sidebar['additionalMailingServiceFinalObj'][index].childs.forEach((child: any, i: number) => {
      this.removeMailingServiceChild(index, i, true);
    });
    delete this.sidebar['additionalModel'][this.sidebar['additionalMailingServiceFinalObj'][index]['mainkey']];
    this.sidebar['additionalMailingServiceFinalObj'].splice(index, 1);
    self.sidebar.additionalOptionValueUpdated(self.sidebar.shipping, self.sidebar.additionalServiceInfo, false);
  }
  removeMailingServiceChild(parentindex: number, index: number, parent = false) {
    if (
      this.sidebar['additionalMailingServiceFinalObj'][parentindex]['childs'][index]['containShippingForm'] === true
    ) {
      if (index === 0 && parent == false) {
        this.removeMailingService(0);
      } else {
        delete this.sidebar['additionalModel'][
          this.sidebar['additionalMailingServiceFinalObj'][parentindex]['childs'][index]['parentKey']
        ];
        ['street_address', 'city', 'state', 'country', 'zip_code', 'phone_number'].forEach(field => {
          delete this.sidebar['additionalModel'][
            this.sidebar['additionalMailingServiceFinalObj'][parentindex]['childs'][index]['key'] + field
          ];
        });
        this.sidebar['additionalMailingServiceFinalObj'][parentindex]['childs'].splice(index - 1, 2);
      }
    } else {
      for (
        let i = this.sidebar['additionalMailingServiceFinalObj'][parentindex]['childs'].length - 1;
        i >= index;
        i--
      ) {
        if (this.sidebar['additionalMailingServiceFinalObj'][parentindex]['childs'][i]['isCopy'] === true) {
          delete this.sidebar['additionalModel'][
            this.sidebar['additionalMailingServiceFinalObj'][parentindex]['childs'][i]['additionKey']
          ];
        }
        delete this.sidebar['additionalModel'][
          this.sidebar['additionalMailingServiceFinalObj'][parentindex]['childs'][i]['key']
        ];
      }
      //this.sidebar['additionalMailingServiceFinalObj'][parentindex]['childs'].splice(index, 1);
    }
    self.sidebar.additionalOptionValueUpdated(self.sidebar.shipping, self.sidebar.additionalServiceInfo, true);
  }
  checkNumber(value: any) {
    return !isNaN(value);
  }
}
