import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Resultbar } from './../models/resultbar.model';

export const MANAGE_RESULT = '[QUOTE] UPDATE';

export class ManageResult implements Action {
  readonly type = MANAGE_RESULT;

  constructor(public payload: Resultbar) {}
}

export type Actions = ManageResult;
