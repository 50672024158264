import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Quote } from './../../models/quote.model';
import { AppState } from './../../app.state';
import * as QuoteActions from './../../actions/quote.actions';
import { QuoteService } from './../service/quote.service';
import { NgForm } from '@angular/forms';
import { FileinfoComponent } from '../fileinfo/fileinfo.component';
declare var $: any;
@Component({
  selector: 'autoquote-basicinfo',
  templateUrl: './basicinfo.component.html',
  styleUrls: ['./basicinfo.component.scss']
})
export class BasicinfoComponent implements OnInit, AfterViewInit {
  autoquote: Quote = <Quote>{};
  is_desktop = false;
  assets_root: string;
  form_panel_label: string = '';
  phone_error: boolean = false;
  step_1: boolean = false;
  step_2: boolean = false;
  @Input() showIframe: boolean = false;
  @Input() selectedOption: {};
  @Input() isAffiliate: boolean = false;
  @ViewChild('quoteForm')
  public quoteForm: NgForm;
  @ViewChild('fileInfo') fileInfo: FileinfoComponent;
  @Input()
  set defaultData(object: any) {
    Object.keys(object).forEach(val => {
      this[val] = object[val];
    });
  }
  @Output() syncToParentData: EventEmitter<any> = new EventEmitter();
  @Output() loader: EventEmitter<any> = new EventEmitter();

  typeOfCertification = [
    { value: '', text: '-- Select --' },
    {
      value: 'cert_free',
      text: 'Certificate of Accuracy by Day Translations (No extra cost)'
    },
    { value: 'ata', text: 'American Translators Association (ATA)', disabled: false },
    {
      value: 'cttic',
      text: 'Canadian Translators, Terminologists and Interpreters Council (CTTIC)'
    },
    {
      value: 'naati',
      text: 'National Accreditation Authority for Translators and Interpreters, Australia (NAATI)'
    },
    {
      value: 'najit',
      text: 'National Association of Judiciary Interpreters & Translators (NAJIT)'
    },
    { value: 'sast_ar', text: 'State appointed sworn translators (Argentina)' },
    { value: 'sast_au', text: 'State appointed sworn translators (Austria)' },
    { value: 'sast_br', text: 'State appointed sworn translators (Brazil)' },
    { value: 'sast_gr', text: 'State appointed sworn translators (Germany)' },
    { value: 'sast_me', text: 'State appointed sworn translators (Mexico)' },
    { value: 'sast_nr', text: 'State appointed sworn translators (Norway)' },
    { value: 'sast_sp', text: 'State appointed sworn translators (Spain)' },
    { value: 'Other', text: 'Others' }
  ];
  notSupportedAtaLangauge: any = [];
  constructor(private quoteService: QuoteService, private router: Router, private store: Store<AppState>) {}
  ngOnInit() {
    this.store.select('quote').subscribe(data => {
      this.loader.emit(false);
      this.autoquote = data[0];
    });
  }
  quoteFormSubmit() {
    this.autoquote.isSubmitted = true;
    this.loader.emit(true);
    this.quoteService
      .addDataToCRM({
        first_name: this.autoquote.first_name,
        last_name: this.autoquote.last_name,
        discount_code: this.autoquote.discount_code,
        type_of_certified_translation: this.autoquote.typeOfCertifiedTranslation,
        other_certification_input: this.autoquote.otherCertificationInput,
        target_language: this.autoquote.target_language,
        source_language: this.autoquote.source_language,
        email: this.autoquote.email,
        phone: this.autoquote.phone,
        notes: this.autoquote.notes,
        files: this.fileInfo.uploader.queue.map((file: any) => file.document_id),
        //company_name: this.autoquote.company_name,
        certifiedTranslation: this.autoquote.isCertifiedTranslation
      })
      .subscribe(
        (res: any) => {
          if (res.status == '200') {
            this.autoquote.step = 3;
            this.autoquote.referenceId = res.data.reference_number_c;
            this.autoquote.quote_type = res.data.type;
            this.autoquote.crm_id = res.data.id;
            this.autoquote.initialProcess = true;
            this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
            this.router.navigate(['thankyou/'], {
              queryParams: { d: 1, id: res.data.id, type: res.data.type }
            });
          }
        },
        err => {}
      );
  }
  goToStep() {
    this['step_1'] = false;
    this['step_2'] = true;
    this.syncToParentData.emit({ step_1: this['step_1'], step_2: this['step_2'] });
  }
  hasError(event: any) {
    if (event === true) {
      this.phone_error = true;
    } else {
      this.phone_error = false;
    }
  }
  onCountryChange() {}
  ngAfterViewInit() {
    $('.flag-container').on('click', function() {
      ['cu', 'kp', 'ir', 'sd', 'sy'].forEach(data => {
        $("li[data-country-code='" + data + "']").remove();
      });
    });
  }
}
