import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
@Component({
  selector: 'app-ongoing',
  templateUrl: './ongoing.component.html',
  styleUrls: ['./ongoing.component.scss']
})
export class OngoingComponent implements OnInit {
  @Input() autoquote: any = {};
  @Input() sidebar: any = {};
  @Input() assets_root: string = environment.domain_folder;
  @Input() discountApplied: boolean = false;
  @Input() is_desktop: boolean = true;
  @Input() isServiceListMinimized: boolean = true;
  @Input() isMailingServiceListMinimized: boolean = true;
  showFiles: boolean = false;
  @Output() downloadFiles = new EventEmitter<string>();
  @Output() deleteAdditionalService = new EventEmitter<number>();
  @Output() deleteAdditionalServiceChild = new EventEmitter<{}>();
  @Output() deleteMailingService = new EventEmitter<number>();
  @Output() deleteMailingServiceChild = new EventEmitter<{}>();
  @Output() isServiceListMinimizedChanged = new EventEmitter<boolean>();
  @Output() isMailingServiceListMinimizedChanged = new EventEmitter<boolean>();
  @Output() clientRequestForInvoice = new EventEmitter<boolean>();
  constructor(private router: Router) {}

  ngOnInit() {}
  downloadFile() {
    this.downloadFiles.emit();
  }
  removeAdditionalService(index: number) {
    this.deleteAdditionalService.emit(index);
  }
  removeAdditionalServiceChild(parent: any, child: any) {
    this.deleteAdditionalServiceChild.emit({ parent, child });
  }
  removeMailingService(index: number) {
    this.deleteMailingService.emit(index);
  }
  removeMailingServiceChild(parent: any, child: any) {
    this.deleteMailingServiceChild.emit({ parent, child });
  }
  checkNumber(value: any) {
    return !isNaN(value);
  }
  discussionPage() {
    this.router.navigate(['discussion/'], {
      queryParams: { id: this.autoquote.crm_id, type: this.autoquote.quote_type }
    });
    return false;
  }
  requestForInvoice() {
    this.clientRequestForInvoice.emit();
  }
}
