import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscussionComponent } from './discussion/discussion.component';
import { DiscussionRoutingModule } from './discussion-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DiscussionComponent],
  imports: [CommonModule, DiscussionRoutingModule, FormsModule, ReactiveFormsModule]
})
export class DiscussionModule {}
