import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Quote } from './../../models/quote.model';
import { AppState } from './../../app.state';
@Component({
  selector: 'autoquote-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  step_1: boolean = false;
  step_2: boolean = false;
  autoquote: Quote = <Quote>{};
  @Input()
  set defaultData(object: any) {
    Object.keys(object).forEach(val => {
      this[val] = object[val];
    });
  }
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.store.select('quote').subscribe(data => {
      this.autoquote = data[0];
    });
  }
}
