import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';

const routes = {
  quote: (c: RandomQuoteContext) => `/jokes/random?category=${c.category}`
};

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface RandomQuoteContext {
  // The quote's category: 'dev', 'explicit'...
  category: string;
}

@Injectable()
export class QuoteService {
  data: any = [];
  additional_service_selected_id: any;
  additional_service_obj: any;
  _apiURL = environment.api_url;
  applicable_object: any;
  constructor(private httpClient: HttpClient) {}

  getRandomQuote(context: RandomQuoteContext): Observable<string> {
    return this.httpClient
      .cache()
      .get(routes.quote(context))
      .pipe(
        map((body: any) => body.value),
        catchError(() => of('Error, could not load joke :-('))
      );
  }

  getOppLeadDetailById(id: any, type: any): Observable<any> {
    return this.httpClient
      .get(this._apiURL + `/autoquote/getOppLeadDetailById/${id}/${type}`)
      .pipe(tap(data => this.response(data)));
  }

  getQuoteAllFiles(data: any): Observable<any> {
    return this.httpClient
      .post(this._apiURL + '/autoquote/getFilesArrayByQuoteId', data)
      .pipe(tap(data => this.response(data)));
  }

  checkUserQuote(email: string): Observable<any> {
    return this.httpClient
      .get(this._apiURL + '/approvals/GetUserQuotes?eml=' + email, httpOptions)
      .pipe(tap(data => this.response(data)));
  }

  getOpAmount(crm_user_id: string): Observable<any> {
    return this.httpClient
      .get(this._apiURL + '/approvals/getOpAmount?crm_user_id=' + crm_user_id, httpOptions)
      .pipe(tap(data => this.response(data)));
  }

  getLeadAmount(crm_user_id: string): Observable<any> {
    return this.httpClient
      .get(this._apiURL + '/approvals/getLeadsAmount?crm_user_id=' + crm_user_id, httpOptions)
      .pipe(tap(data => this.response(data)));
  }

  updateNotificationSetting(notificationData: any): Observable<any> {
    let body = new FormData();
    body.append('crm_id', notificationData.crm_id);
    body.append('crm_type', notificationData.crm_type);
    body.append('mode', notificationData.mode);
    body.append('phone_number', notificationData.phone_number);
    return this.httpClient
      .post(this._apiURL + '/approvals/updateNotificationSetting', body)
      .pipe(tap(data => this.response(data)));
  }

  getUserFullDetail(crm_user_id: string, crm_user_type: string): Observable<any> {
    return this.httpClient
      .get(
        this._apiURL + '/approvals/getUserFullDetail?crm_user_id=' + crm_user_id + '&crm_user_type=' + crm_user_type,
        httpOptions
      )
      .pipe(tap(data => this.response(data)));
  }

  additionalServices(ShippingAddress: any, additonal_submitted_id: any) {
    this.additional_service_selected_id = additonal_submitted_id;
    this.additional_service_obj = ShippingAddress;
  }

  applicableServices(applicable_send_object: any, additonal_submitted_id: any) {
    this.additional_service_selected_id = additonal_submitted_id;
    this.applicable_object = applicable_send_object;
  }
  sendRenegotiate(renegotiateData: any): Observable<any> {
    let body = new FormData();
    body.append('query_description', renegotiateData.renegotiate_text);
    body.append('crm_type', renegotiateData.crm_type);
    body.append('crm_user_id', renegotiateData.crm_user_id);
    return this.httpClient
      .post(this._apiURL + '/approvals/sendRenegotiate', body)
      .pipe(tap(data => this.response(data)));
  }

  sendDiscussion(discussionData: any): Observable<any> {
    let body = new FormData();
    body.append('query_description', discussionData.query_description);
    body.append('crm_type', discussionData.crm_type);
    body.append('crm_user_id', discussionData.crm_user_id);
    return this.httpClient
      .post(this._apiURL + '/approvals/sendDiscussion', body)
      .pipe(tap(data => this.response(data)));
  }

  addFeedback(feedbackData: any): Observable<any> {
    let body = new FormData();
    body.append('rating', feedbackData.rating);
    body.append('comment', feedbackData.comment);
    body.append('crm_user_name', feedbackData.crm_user_name);
    body.append('crm_user_id', feedbackData.crm_user_id);
    return this.httpClient.post(this._apiURL + '/approvals/addFeedback', body).pipe(tap(data => this.response(data)));
  }

  sendDecline(declineData: any): Observable<any> {
    let body = new FormData();
    body.append('crm_type', declineData.crm_type);
    body.append('crm_user_id', declineData.crm_user_id);
    body.append('why_not_approved_checkbox_other', declineData.why_not_approved_checkbox_other);
    body.append('how_we_can_improve', declineData.how_we_can_improve);
    body.append('why_not_approved_checkbox', declineData.why_not_approved_checkbox);

    return this.httpClient.post(this._apiURL + '/approvals/declineQuote', body).pipe(tap(data => this.response(data)));
  }

  paymentProcessService(paymentDetail: any, additionalServiceData: any): Observable<any> {
    const data = { ...paymentDetail, ...additionalServiceData };
    return this.httpClient
      .post(this._apiURL + '/approvals/submitApprovalRevised', data)
      .pipe(tap(data => this.response(data)));
  }

  paymentEmailProcessService(paymentDetail: any): Observable<any> {
    return this.httpClient
      .post(this._apiURL + '/approvals/submitPayment', paymentDetail)
      .pipe(tap(data => this.response(data)));
  }

  getHistory(email: string): Observable<any> {
    return this.httpClient
      .get(this._apiURL + '/approvals/getUserHistory?email=' + email, httpOptions)
      .pipe(tap(data => this.response(data)));
  }
  getAllLanguages(): Observable<any> {
    return this.httpClient.get(this._apiURL + '/autoquote/getAllLanguage').pipe(tap(data => this.response(data)));
  }
  response(data: any) {
    this.data = data;
  }
}
