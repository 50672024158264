import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as QuoteActions from './../actions/quote.actions';
import { AppState } from './../app.state';
import { Quote } from './../models/quote.model';
import { QuoteService } from './service/quote.service';
import { MetaService } from '../shared/meta.service';
import { DOCUMENT } from '@angular/common';
declare var jQuery: any;
declare var $: any;
@Component({
  selector: 'app-autoquote',
  templateUrl: './autoquote.component.html'
})
export class AutoquoteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('basicInfo') basicInfo: ElementRef;
  @ViewChild('quoteInfo') quoteInfo: ElementRef;
  @ViewChild('fileInfo') fileInfo: ElementRef;
  @ViewChild('additionalServices') additionalServices: ElementRef;
  assets_root = environment.domain_folder;
  show_loader: boolean = true;
  autoquote: Quote = <Quote>{};
  whats_link: any = '';
  step_1: boolean = true;
  step_2: boolean = false;
  step_3: boolean = false;
  step_4: boolean = false;
  step_5: boolean = false;
  step_6: boolean = false;
  is_desktop: any = true;
  activatedRouteService$: Subscription;
  availableFormsiteQuote: string[] = [
    'translation-quote',
    'interpreting-quote',
    'certified-quote',
    'multilingual-marketing-quote',
    'business-services-quote',
    'localization-quote',
    'transcription-quote',
    'subtitling-quote',
    'academic-evaluation'
  ];
  availableFormSiteParam: any = {
    'interpreting-quote': {
      id: 'frame-1048018999',
      class: 'interpreting-quote',
      src:
        'https://fs18.formsite.com/res/showFormEmbed?EParam=B6fiTn-RcO6YeUZ_FMAmUF40ZriNbs5GK5oMHUOxJy8&EmbedId=1048018999'
    },
    'certified-quote': {
      id: 'frame-one2147007453',
      class: 'certified-quote',
      src:
        'https://fs18.formsite.com/res/showFormEmbed?EParam=B6fiTn-RcO6YeUZ_FMAmUIo7PSJlfGHAknr8BrCgzCc&2147007453&EmbedId=2147007453'
    },
    'multilingual-marketing-quote': {
      id: 'frame-446121667',
      class: 'multilingual-marketing-quote',
      src:
        'https://fs18.formsite.com/res/showFormEmbed?EParam=B6fiTn-RcO6YeUZ_FMAmUJi3aa5MNbjA6i5F2Wdvk90&446121667&EmbedId=446121667'
    },
    'business-services-quote': {
      id: 'frame-one1217383604',
      class: 'business-services-quote',
      src:
        'https://fs18.formsite.com/res/showFormEmbed?EParam=B6fiTn-RcO6YeUZ_FMAmUD0B1Onthas_6i5F2Wdvk90&1474398857&EmbedId=1474398857'
    },
    'localization-quote': {
      id: 'frame-1037496560',
      class: 'localization-quote',
      src:
        'https://fs18.formsite.com/res/showFormEmbed?EParam=B6fiTn-RcO6YeUZ_FMAmUHSKlBogGjHGknr8BrCgzCc&1037496560&EmbedId=1037496560'
    },
    'transcription-quote': {
      id: 'frame-1511907081',
      class: 'transcription-quote',
      src:
        'https://fs18.formsite.com/res/showFormEmbed?EParam=B6fiTn-RcO6YeUZ_FMAmUDJxTswAK2Z76i5F2Wdvk90&1511907081&EmbedId=1511907081'
    },
    'subtitling-quote': {
      id: 'frame-sdsdf',
      class: 'subtitling-quote',
      src: 'https://fs18.formsite.com/shopwood/yn78wafekw/index.html'
    },
    'academic-evaluation': {
      id: 'frame-sdsdaf',
      class: 'academic-evaluation',
      src: 'https://fs18.formsite.com/shopwood/2a9jhbjoju/index.html'
    }
  };
  showIframe: boolean = false;
  selectedOption: any = '';
  isTopbarCookie = this.getCookie('show_additional_discount') == '' ? false : true;
  constructor(
    private quoteService: QuoteService,
    private routes: ActivatedRoute,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private store: Store<AppState>,
    private metaService: MetaService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    this.is_desktop = !isMobile && !isTablet;
    if (isMobile === true) {
      this.whats_link =
        "whatsapp://send?phone=17185508412&text=Hi, I would like to know more about my translation order. Let's chat!";
    } else if (isTablet === true) {
      this.whats_link =
        "whatsapp://send?phone=17185508412&text=Hi, I would like to know more about my translation order. Let's chat!";
    } else if (isDesktopDevice === true) {
      this.whats_link =
        'https://web.whatsapp.com/send?phone=17185508412&text=Hi,%20I%20would%20like%20to%20know%20more%20about%20my%20translation%20order.%20Let%27s%20chat!';
    } else {
      this.whats_link =
        'https://web.whatsapp.com/send?phone=17185508412&text=Hi,%20I%20would%20like%20to%20know%20more%20about%20my%20translation%20order.%20Let%27s%20chat!';
    }
    store.select('quote').subscribe(data => {
      this.autoquote = data[0];
      //this.autoquote.step = 4;
      this.goToStep(this.autoquote.step);
    });
  }
  goToStep(step: any) {
    for (var i = 1; i <= 6; i++) {
      this['step_' + i] = false;
    }
    this['step_' + step] = true;
  }
  segment: string = '';
  ngOnInit() {
    this.metaService.createCanonicalURL();
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    const routeArray = this.router.url.split('/');
    this.segment = '';
    if (routeArray[1]) {
      this.segment = routeArray[1];
    }
    if (this.segment != '') {
      if (this.segment.includes('translation-quote')) {
        this.showIframe = false;
        this.syncToParentData({ step_1: false, step_2: true });
        this.autoquote.project_type = 'Translation';
        this.autoquote.isCertifiedTranslation = 'no';
      } else if (this.segment.includes('certified-quote')) {
        this.showIframe = false;
        this.syncToParentData({ step_1: false, step_2: true });
        this.autoquote.project_type = 'Certified Translation';
        this.autoquote.isCertifiedTranslation = 'yes';
      } else {
        Object.keys(this.availableFormSiteParam).forEach(key => {
          if (this.segment.includes(key)) {
            this.syncToParentData({ step_1: false, step_2: true });
            this.selectedOption = this.availableFormSiteParam[key];
            this.showIframe = true;
          }
        });
      }
    }
  }

  getCookie(cname: string) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  ngAfterViewInit() {
    setTimeout(function() {
      scrollTo(0, -1);
    }, 0);
    this.show_loader = true;
    this.routes.queryParams.subscribe(params => {
      if (params['id'] != '' && params['id'] != null) {
        this.quoteService.getOppLeadDetailById(params['id'], params['type']).subscribe((res: any) => {
          if (res != null && res.status == 200) {
            this.show_loader = false;
            Object.keys(res.data.redux).forEach((val: any, index: any) => {
              if ('due_date' == val && res.data.redux[val] != '') {
                this.autoquote[val] = new Date(res.data.redux[val]);
              } else {
                this.autoquote[val] = res.data.redux[val];
              }
            });
            this.store.dispatch(new QuoteActions.AddUpdateQuote(this.autoquote));
          } else {
            this.router.navigate(['/']);
          }
        });
      } else {
        this.show_loader = false;
      }
    });

    jQuery('.modal.fade, #videoModal .close, #videoModal .btn').on('click', function() {
      jQuery('#videoModal iframe').attr('src', jQuery('#videoModal iframe').attr('src'));
    });
  }
  ngOnDestroy() {
    if (this.activatedRouteService$) {
      this.activatedRouteService$.unsubscribe();
    }
  }
  syncToParentData(object: any) {
    Object.keys(object).forEach(val => {
      this[val] = object[val];
    });
  }
  loaderInit($event: any) {
    this.show_loader = $event;
  }
}
